const axios = require('../config/axios.config.js').default;

export default {
  namespaced: true,
  state: () => ({
    isLogin: false,
    auth: {},
    require_auth: false,
    infoMeta: {},
  }),

  getters: {
    Auth: (state) => state.auth,
    Require_auth: (state) => state.require_auth,
    InfoMeta: (state) => state.infoMeta,
  },

  actions: {
    req_postLogin({ commit }, input) {
      axios.post('login', input).then((response) => {
        if (response) {
          commit('LOGIN_SUCCESS', response);
        }
      });
    },
    req_postRegister({ commit }, input) {
      axios.post('register', input).then((response) => {
        if (response) {
          commit('REGISTER_SUCCESS');
        }
      });
    },
    req_postRegisterMemberChild({ commit, dispatch }, input) {
      axios.post('add-member', input).then((response) => {
        if (response) {
          commit('REGISTER_MEMBER_CHILD_SUCCESS');
          this.dispatch('member/req_postListMember', { page: 1 });
        }
      });
    },
    req_postForgotPassword({ commit }, input) {
      axios.post('forget', input).then((response) => {
        if (response) {
          commit('FORGOT_SUCCESS');
        }
      });
    },
    req_changePassword({ commit }, input) {
      axios.post('profile/change-password', input).then((response) => {
        if (response) {
          commit('CHANGE_PASSWORD_SUCCESS');
        }
      });
    },
    req_updatePassword({ commit }, input) {
      axios.post('auth/address/update-password', input).then((response) => {
        if (response) {
          commit('UPDATE_PASSWORD_SUCCESS');
        }
      });
    },
    req_updateEmail({ commit }, input) {
      axios.post('auth/address/update-email', input).then((response) => {
        if (response) {
          commit('UPDATE_EMAIL_SUCCESS');
        }
      });
    },
    req_getAuth({ commit }) {
      axios.get('auth/get-auth').then((response) => {
        if (response) {
          commit('GET_AUTH_SUCCESS', response);
        }
      });
    },
    req_changeAuth({ commit }, input) {
      axios.post('auth/confirm-auth', input).then((response) => {
        if (response) {
          commit('CHANGE_AUTH_SUCCESS', response);
        }
      });
    },
    req_postLoginWithMetamask({ commit }, input) {
      axios.post('login-metamask', input).then((response) => {
        if (response) {
          commit('LOGIN_METAMASK_SUCCESS', response);
        }
      });
    },
    req_getInfoMetamask({ commit }) {
      axios.get('metamask/info').then((response) => {
        if (response) {
          commit('GET_INFO_META_SUCCESS', response);
        }
      });
    },
    req_confirmConnectWallet({ dispatch }, input) {
      axios.post('metamask/confirm-connect', input).then((response) => {
        if (response) {
          dispatch('req_getInfoMetamask');
        }
      });
    },
    req_disConfirmConnectWallet({ dispatch }, input) {
      axios.post('metamask/disconnect-connect', input).then((response) => {
        if (response) {
          dispatch('req_getInfoMetamask');
        }
      });
    },
  },
  mutations: {
    LOGIN_SUCCESS(state, data) {
      if (!data.data.require_auth) {
        state.isLogin = true;
        window.$cookies.set('isSession', { key: data.data.token });
        window.$cookies.set('isLogin', true);
        axios.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
        state.require_auth = false;
      } else {
        state.require_auth = data.data.require_auth;
      }
      // this.dispatch('user/req_getInfo', {}, { root: true });
    },
    LOGOUT_SUCCESS() {
      window.$cookies.remove('isSession');
      window.$cookies.remove('isLogin');
    },
    REGISTER_SUCCESS() {},
    FORGOT_SUCCESS() {},
    CHANGE_PASSWORD_SUCCESS() {},
    UPDATE_PASSWORD_SUCCESS() {},
    UPDATE_EMAIL_SUCCESS() {},
    REGISTER_MEMBER_CHILD_SUCCESS() {},
    GET_AUTH_SUCCESS(state, data) {
      state.auth = data.data;
    },
    CHANGE_AUTH_SUCCESS(state, data) {
      state.auth = !state.Auth;
    },
    LOGIN_METAMASK_SUCCESS(state, data) {
      // console.log(data);
      if (!data.data.require_auth) {
        state.isLogin = true;
        window.$cookies.set('isSession', { key: data.data.token });
        window.$cookies.set('isLogin', true);
        axios.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
        state.require_auth = false;
      } else {
        state.require_auth = data.data.require_auth;
      }
    },
    GET_INFO_META_SUCCESS(state, data){
      state.infoMeta = data.data;
    },
  },
};
