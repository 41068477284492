<template>
  <div>
    <b-modal :id="`modal-getPassword`" hide-footer hide-header>
      <div class="has-close" @click="handleClickBackLogin()">
        <i class="fas fa-chevron-left"></i>{{ $t('GetPass.101') }}
      </div>
      <button class="close-icon" @click="$bvModal.hide(`modal-getPassword`)">
        <i class="fas fa-times"></i>
      </button>
      <div class="form-login" style="transform: none">
        <div class="login">
          <div class="box">
            <div class="input">
              <div class="input-label">
                <div style="flex: 1 1 0%">{{ $t('GetPass.102') }}</div>
              </div>
              <div class="input-control">
                <input type="email" value="" v-model="formForget.User_Email" />
              </div>
            </div>
          </div>
          <hr />
          <div class="buttons">
            <button class="button button-big" @click="onForgetPass">
              <div class="button-inner">{{ $t('GetPass.101') }}</div>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  export default {
    name: 'GetPassword',
    props: ['type'],
    data() {
      return {
        formForget: {
          User_Email: '',
        },
      };
    },
    computed: {},
    methods: {
      ...mapMutations(['onLoad', 'outLoad']),
      handleClickBackLogin() {
        this.$emit('onBackLogin');
      },
      onForgetPass() {
        this.onLoad();
        this.$store.dispatch('auth/req_postForgotPassword', this.formForget);
      },
    },
    created() {
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        // eslint-disable-next-line default-case
        switch (mutation.type) {
          case 'core/SUCCESS_MESSANGE':
            this.formForget.User_Email = '';
            break;
          case 'core/ERROR_MESSANGE':
            this.formForget.User_Email = '';
            break;
        }
      });
    },
    beforeDestroy() {
      this.unsubscribe();
    },
  };
</script>
<style lang="scss">
  #modal-getPassword {
    .modal-dialog {
      height: 100%;
      @media (max-width: 575px) {
        margin: 0px;
      }
    }
    .modal-content {
      background-color: #17181b;
      border-radius: 1.25rem;
      .modal-body {
        padding: 0;
      }
    }
  }
</style>
<style scoped lang="scss">
  .has-close {
    background-color: transparent;
    box-shadow: none;
    margin-right: 3.75rem;
    position: relative;
    z-index: 10;
    flex: 0 0 auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 3.75rem;
    margin-left: 1.125rem;
    transition: all 0.5s ease 0s;
    color: #fff;
    font-size: bold;
    font-size: 20px;
    @media (max-width: 375px) {
      font-size: 14px;
    }
    svg {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .close-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 11;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 3.75rem;
    height: 3.75rem;
    background: none;
    border: none;
    svg {
      width: 1.4em;
      height: 1.4em;
      transition: transform 0.5s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
      cursor: pointer;
      font-size: 0.75rem;
      color: rgb(255, 255, 255);
    }
  }
  .form-login {
    width: 100%;
    height: auto;
    border-radius: 1.25rem;
    background-color: rgb(23, 24, 27);
    .login {
      background-color: rgb(30, 32, 36);
      border-radius: 1.25rem;
      height: 700px;
      .box {
        padding: 1.5rem 1.25rem;
        .input {
          &:nth-child(1) {
            margin-top: 0.25rem;
            .input-label {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              line-height: 1em;
              height: 1.25rem;
              margin: 0px 0.75rem 0.375rem;
              color: rgba(153, 164, 176, 0.6);
            }
          }
          .input-control {
            position: relative;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            border-radius: 1.25rem;
            border: 1px solid rgb(45, 48, 53);
            background-color: rgba(45, 48, 53, 0.5);
            height: 3.5rem;
            opacity: 1;
            @media (max-width: 575px) {
              height: 2.5rem;
              font-size: 16px !important;
            }
            input {
              width: 100%;
              height: 100%;
              padding: 20px;
              border: none;
              background: none;
              color: rgb(245, 246, 247);
              border-radius: 1.25rem;
              &:focus-visible {
                outline: none;
                border: 1px solid rgb(93, 160, 0);
              }
            }
          }
        }
      }
      hr {
        height: 1px;
        margin: 0px;
        border: none;
        background-color: rgba(62, 72, 79, 0.3);
      }
      .buttons {
        padding: 1.25rem 2.5rem;
        display: flex;
        justify-content: center;
        .button {
          border: none;
          &:nth-child(1) {
            width: 15rem;
            flex: 0 0 auto;
            margin-right: 0.625rem;
            height: 3.625rem;
            color: rgb(245, 246, 247);
            box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
            background-color: rgb(93, 160, 0);
            background-image: conic-gradient(
              from 1turn,
              rgb(69, 136, 2),
              rgb(123, 197, 20)
            );
            font-weight: bold;
            cursor: pointer;
            transition: transform 0.2s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
            border-radius: 6.25rem;
            display: block;
            @media (max-width: 575px) {
              height: 2.5rem;
              font-size: 14px;
            }
          }
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
</style>
