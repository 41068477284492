<template>
  <b-modal :id="`modal-copy-${type}`" hide-footer modal-class="modal-copy">
    <div class="copy-content">
      <img src="~@/assets/images/logo.png" alt="" />
      <div class="copy-content-test">{{ $t('PopupCopy.54') }}: {{ content }}</div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    props: ['content', 'type'],
  };
</script>
<style lang="scss">
  .modal-copy {
    &.modal {
      padding-left: 0px !important;
      .modal-dialog {
        margin: 100px auto;
        @media (max-width: 576px){
          margin: 100px 0px;
        }
        &::-webkit-scrollbar {
          width: 0px !important;
        }
        .modal-content {
          background-color: #17181b;
          .modal-header {
            border: 0px;
            padding: 10px 15px 0px 0px;
            .close {
              color: #fff;
            }
          }
          .modal-body {
            padding: 0px;
            margin-top: 10px;
          }
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  .copy-content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    img {
      width: auto;
      height: 100px;
    }
    .copy-content-test {
      text-align: center;
      margin-bottom: 20px;
    }
  }
</style>
