import store from '../../store';

export default function auth({ next, router }) {
	if (!window.$cookies.get('isSession')) {
		store.commit('auth/LOGOUT_SUCCESS');
		return router.push({ name: 'Home' });
	}
	store.commit('login');
	return next();
}
