const axios = require('../config/axios.config.js').default;

export default {
  namespaced: true,
  state: () => ({
    history: {},
    totalPages: 1,
    currentPage: 1,
    listCoin: {},
    listEcosystem: {},
    coinDeposit: {},
    dataLoginAgin: {},
    addr: '',
    dataLoginEvolution: {},
  }),
  getters: {
    History: (state) => state.history,
    ListCoin: (state) => state.listCoin,
    ListEcosystem: (state) => state.listEcosystem,
    TotalPages: (state) => state.totalPages,
    CoinDeposit: (state) => state.coinDeposit,
    DataLoginAgin: (state) => state.dataLoginAgin,
    DataLoginEvolution: (state) => state.dataLoginEvolution,
  },
  actions: {
    req_depositGame({ commit }, input) {
      axios.post('game/deposit', input).then((response) => {
        if (response) {
          commit('DEPOSIT_GAME_SUCCESS', response);
        }
      });
    },
    req_withdrawGame({ commit }, input) {
      axios.post('game/withdraw', input).then((response) => {
        if (response) {
          commit('WITHDRAW_SUCCESS', response);
        }
      });
    },
    req_getHistory({ commit }, input) {
      axios
        .get('report/wallet', {
          params: input,
        })
        .then((response) => {
          if (response) {
            commit('GET_HISTORY_SUCCESS', response);
          }
        });
    },
    // req_getCoin({ commit }, input) {
    //   axios.get('report/wallet', input).then((response) => {
    //     if (response) {
    //       commit('GET_COIN_SUCCESS', response);
    //     }
    //   });
    // },
    req_getListEcosystem({ commit }, input) {
      axios.get('report/wallet', input).then((response) => {
        if (response) {
          commit('GET_ECOSYSTEM_SUCCESS', response);
          commit('GET_COIN_SUCCESS', response);
        }
      });
    },
    req_getHistoryGame({ commit }, input) {
      axios
        .get('report/game', {
          params: input,
        })
        .then((response) => {
          if (response) {
            commit('GET_HISTORY_GAME_SUCCESS', response);
          }
        });
    },
    req_getHistoryLogin({ commit }, input) {
      axios.get('report/login', input).then((response) => {
        if (response) {
          commit('GET_HISTORY_LOGIN_SUCCESS', response);
        }
      });
    },
    req_getDeposit({ commit }, input) {
      axios
        .get('wallet/deposit', { params: { coin: input } })
        .then((response) => {
          if (response) {
            commit('GET_DEPOSIT_SUCCESS', response);
          }
        });
    },
    // wallet/withdraw-ebp
    req_withdraw({ commit, dispatch }, input) {
      axios.post('wallet/withdraw-user', input).then((response) => {
        if (response) {
          commit('WITHDRAW_SUCCESS', response);
        }
      });
    },
    req_transfer({ commit }, input) {
      axios.post('wallet/transfer', input).then((response) => {
        if (response) {
          commit('TRANSFER_SUCCESS', response);
        }
      });
    },
    req_swap({ commit }, input) {
      axios.post('wallet/swap-ebp', input).then((response) => {
        if (response) {
          commit('SWAP_SUCCESS', response);
        }
      });
    },
    // Vm555
    req_registerVm555({ commit }, input) {
      axios.post('wm555/register', input).then((response) => {
        if (response) {
          commit('REGISTER_VM555_SUCCESS', response);
        }
      });
    },
    req_changePasssVm555({ commit }, input) {
      axios.post('wm555/change-password', input).then((response) => {
        if (response) {
          commit('CHANGEPASSWORD_VM555_SUCCESS', response);
        }
      });
    },
    req_depositWM555({ commit }, input) {
      axios.post('wm555/deposit', input).then((response) => {
        if (response) {
          commit('DEPOSIT_VM555_SUCCESS', response);
        }
      });
    },
    req_withdrawWM555({ commit }, input) {
      axios.post('wm555/withdraw', input).then((response) => {
        if (response) {
          commit('WITHDRAW_VM555_SUCCESS', response);
        }
      });
    },
    // Agin
    req_registerAgin({ commit }, input) {
      axios.post('agin-sportbook/create-member', input).then((response) => {
        if (response) {
          commit('REGISTER_AGIN_SUCCESS', response);
        }
      });
    },
    req_changePasssAgin({ commit }, input) {
      axios.post('agin-sportbook/change-password', input).then((response) => {
        if (response) {
          commit('CHANGEPASSWORD_AGIN_SUCCESS', response);
        }
      });
    },
    req_depositAgin({ commit }, input) {
      axios.post('agin-sportbook/deposit', input).then((response) => {
        if (response) {
          commit('DEPOSIT_AGIN_SUCCESS', response);
        }
      });
    },
    req_withdrawAgin({ commit }, input) {
      axios.post('agin-sportbook/withdraw', input).then((response) => {
        if (response) {
          commit('WITHDRAW_AGIN_SUCCESS', response);
        }
      });
    },
    // Evolution
    req_registerEvolution({ commit }, input) {
      axios.post('evolution/login', input).then((response) => {
        if (response) {
          commit('REGISTER_EVOLUTION_SUCCESS', response);
        }
      });
    },
    req_loginEvolution({ commit }, input) {
      axios.post('evolution/login', input).then((response) => {
        if (response) {
          commit('LOGIN_EVOLUTION_SUCCESS', response);
        }
      });
    },
    req_changePasssEvolution({ commit }, input) {
      axios.post('evolution/change-password', input).then((response) => {
        if (response) {
          commit('CHANGEPASSWORD_EVOLUTION_SUCCESS', response);
        }
      });
    },
    req_depositEvolution({ commit }, input) {
      axios.post('evolution/deposit', input).then((response) => {
        if (response) {
          commit('DEPOSIT_EVOLUTION_SUCCESS', response);
        }
      });
    },
    req_withdrawEvolution({ commit }, input) {
      axios.post('evolution/withdraw', input).then((response) => {
        if (response) {
          commit('WITHDRAW_EVOLUTION_SUCCESS', response);
        }
      });
    },
    // --------------------------
    req_postWithdrawBonus({ commit }, input) {
      axios.post('game/withdraw-bonus-birthday', input).then((response) => {
        if (response) {
          commit('WITHDRAW_BONUS_SUCCESS', response);
        }
      });
    },
    req_postLoginAginGame({ commit }, input) {
      axios.post('agin-sportbook/login', input).then((response) => {
        if (response) {
          commit('LOGIN_AIGN_SUCCESS', response);
        }
      });
    },
  },
  mutations: {
    async setupWallet(state, input) {
      state.addr = input;
    },
    DEPOSIT_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    DEPOSIT_GAME_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    WITHDRAW_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    TRANSFER_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    SWAP_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    REGISTER_VM555_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    REGISTER_AGIN_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    CHANGEPASSWORD_VM555_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    CHANGEPASSWORD_AGIN_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    DEPOSIT_VM555_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    DEPOSIT_AGIN_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    WITHDRAW_VM555_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    WITHDRAW_AGIN_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    GET_HISTORY_SUCCESS(state, data) {
      state.history = data.data.history;
      state.totalPages = data.data.total_page;
    },
    GET_COIN_SUCCESS(state, data) {
      console.log(data.coin);
      state.listCoin = data.coin;
    },
    GET_ECOSYSTEM_SUCCESS(state, data) {
      state.listEcosystem = data.data.ecosystem;
    },
    GET_HISTORY_GAME_SUCCESS(state, data) {
      state.history = data.data.history;
      state.totalPages = data.data.total_page;
    },
    GET_HISTORY_LOGIN_SUCCESS(state, data) {
      state.history = data.data.history;
      state.totalPages = data.data.total_page;
    },
    GET_DEPOSIT_SUCCESS(state, data) {
      state.coinDeposit = data.data;
    },
    WITHDRAW_BONUS_SUCCESS() {
      this.dispatch('dashboard/req_getDashboard', 1);
      this.dispatch('dashboard/req_getAgency');
    },
    LOGIN_AIGN_SUCCESS(state, data) {
      // console.log(data);
      state.dataLoginAgin = data.data;
    },
    // Evolution
    CHANGEPASSWORD_EVOLUTION_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    REGISTER_EVOLUTION_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    DEPOSIT_EVOLUTION_SUCCESS() {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    WITHDRAW_EVOLUTION_SUCCESS(state, data) {
      this.dispatch('dashboard/req_getDashboard', 1);
    },
    LOGIN_EVOLUTION_SUCCESS(state, data) {
      console.log(data);
      state.dataLoginEvolution = data.data.entryEmbedded;
    },
  },
};
