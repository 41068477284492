import { BootstrapVue } from 'bootstrap-vue';
import VueLazyload from 'vue-lazyload';
import VueSweetalert2 from 'vue-sweetalert2';
import VueToastr from 'vue-toastr';
import VueClipboard from 'vue-clipboard2';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Paginate from 'vuejs-paginate';
import DatePicker from 'vue2-datepicker';
const numeral = require('numeral');
import Vue from 'vue';

import router from './router';
import store from './store';
import App from './App.vue';
import Web3 from 'web3';

import './assets/style/scss/app.scss';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue2-datepicker/index.css';

VueClipboard.config.autoSetContainer = true;
Vue.use(VueSweetalert2);
Vue.use(VueClipboard);
Vue.use(BootstrapVue);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: './assets/LazyLoad/error.png',
  loading: './assets/LazyLoad/loading.gif',
  attempt: 1,
  silent: true,
  //   listenEvents: ["scroll"],
});
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_V3_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
});
Vue.use(VueToastr, {
  defaultTimeout: 3000,
  defaultPosition: 'toast-top-right',
  defaultClassNames: ['animate__animated ', 'animate__bounceInRight'],
});
Vue.component('paginate', Paginate);
Vue.component('datepicker', DatePicker);
Vue.mixin({
  methods: {
    TruncateToDecimals2(num, type, dec = 2) {
      const numberFormat = parseFloat(num.toFixed(10));
      const d = numberFormat.toString().split('.');
      if (type === 1) {
        return `${numeral(d[0]).format(0, 0)}`;
      }
      return `${numeral(d[0]).format(0, 0)}${
        d[1] ? `.${d[1].slice(0, dec)}` : ''
      }`;
    },
    num_format(value, dec) {
      if (dec > 0) {
        return (+value).toFixed(dec);
      } else {
        return numeral(value).format('0,0');
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async genarateCaptChaV3(nameCaptCha) {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha(nameCaptCha);
      return token;
    },
    redirectTo(name) {
      router.push({ name });
    },
    redirectToUrlNoId(url) {
      window.location.assign(`${url}?view=true`);
    },
    async onConnectWallet() {
      await this.checkWallet();
    },
    async checkWallet() {
      // return;
      this.onLoad();
      if (!window.ethereum) {
        this.outLoad();
        this.$toastr.e(this.$t('Message.239'), this.$t('Message.240'));
        return false;
      }
      let addrs = [];
      try {
        if (window.ethereum.isSafePal) {
          addrs[0] = window.ethereum.address;
        } else {
          addrs = await window.ethereum.request({
            method: 'eth_requestAccounts',
          });
        }
      } catch (error) {
        this.outLoad();
        if (
          error.message ===
          'Already processing eth_requestAccounts. Please wait.'
        ) {
          this.$toastr.e(this.$t('Message.241'), this.$t('Message.240'));
          return false;
        }
        this.$toastr.e(error.message, this.$t('Message.240'));
        return false;
      }
      if (addrs.length > 0) {
        await this.$store.commit(
          'wallet/setupWallet',
          Web3.utils.toChecksumAddress(addrs[0])
        );
        // eslint-disable-next-line prefer-destructuring
        this.addressCurrent = addrs[0];
        this.isConnected = true;
        this.outLoad();
        return true;
      }
    },
  },
});
// import fontawesome
import '@/assets/style/font/font-awesome/css/duotone.min.css';
import '@/assets/style/font/font-awesome/css/fontawesome.min.css';
import '@/assets/style/font/font-awesome/css/solid.min.css';
import '@/assets/style/font/font-awesome/css/regular.min.css';
import './assets/style/font/font-awesome/css/brands.min.css';

import './assets/style/font/font-awesome/js/fontawesome';
import './assets/style/font/font-awesome/js/solid';
import './assets/style/font/font-awesome/js/duotone';
import './assets/style/font/font-awesome/js/regular';
import './assets/style/font/font-awesome/js/brands';
import i18n from './i18n';

Vue.config.productionTip =
  process.env.NODE_ENV === 'development' ? true : false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
