import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from './middleware/auth';
import log from './middleware/log';
import GlobalLayout from '../layouts/Body.vue';

function LoadingComponents(folder, view) {
  return () => import(`@/views/${folder}/${view}.vue`);
}
import  firework from '@/components/Background/Firework/Fireworks.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/',
    component: GlobalLayout,
    redirect: '',
    children: [
      {
        path: '/404',
        component: LoadingComponents('errors', '404'),
        name: '404',
        meta: {
          title: 'PAGE NOT FOUND',
          middleware: log,
        },
      },
      {
        path: '',
        component: LoadingComponents('Home', 'MainContent'),
        name: 'Home',
        meta: {
          title: 'Home Page',
          middleware: log,
        },
      },
      {
        path: '/position',
        component: LoadingComponents('Position', 'index'),
        name: 'Position',
        meta: {
          title: 'Slot Game',
          middleware: log,
        },
      },
      {
        path: '/live-casion',
        component: LoadingComponents('LiveCasino', 'index'),
        name: 'LiveCasino',
        meta: {
          title: 'Live Casino',
          middleware: log,
        },
      },
      {
        path: '/transaction-history',
        component: LoadingComponents('Transaction', 'HistoryTransaction'),
        name: 'TransactionHistory',
        meta: {
          title: 'Transaction History',
          middleware: auth,
        },
      },
      {
        path: '/vip-club',
        component: LoadingComponents('VipClub', 'index'),
        name: 'VipClub',
        meta: {
          title: 'Vip Club',
          middleware: auth,
        },
      },
      {
        path: '/member',
        component: LoadingComponents('Member', 'index'),
        name: 'Member',
        meta: {
          title: 'Member',
          middleware: auth,
        },
      },
      {
        path: '/help',
        component: LoadingComponents('Help', 'index'),
        name: 'Help',
        meta: {
          title: 'Help',
          middleware: log,
        },
      },
      {
        path: '/liquidity-partner',
        component: LoadingComponents('LiquidityPartner', 'index'),
        name: 'LiquidityPartner',
        meta: {
          title: 'Liquidity Partner',
          middleware: log,
        },
      },
      {
        path: '/cooperation-contact',
        component: LoadingComponents('CooperationContact', 'index'),
        name: 'CooperationContact',
        meta: {
          title: 'Cooperation Contact',
          middleware: log,
        },
      },
      {
        path: '/support',
        component: LoadingComponents('Support', 'index'),
        name: 'Support',
        meta: {
          title: 'Support',
          middleware: auth,
        },
      },
      {
        path: '/report-win-lost',
        component: LoadingComponents('Reports', 'Win_Loss'),
        name: 'Win_Loss',
        meta: {
          title: 'Reports | Win Loss',
          middleware: auth,
        },
      },
      {
        path: '/report-signin',
        component: LoadingComponents('Reports', 'SignIn'),
        name: 'SignIn',
        meta: {
          title: 'Reports | Sign In',
          middleware: auth,
        },
      },
      {
        path: '/report-transaction',
        component: LoadingComponents('Transaction', 'HistoryTransaction'),
        name: 'Transaction',
        meta: {
          title: 'Reports | Transaction',
          middleware: auth,
        },
      },
      {
        path: '/spin',
        component: LoadingComponents('Spin', 'index'),
        name: 'Spin',
        meta: {
          title: 'Spin',
          middleware: auth,
        },
      },
      {
        path: '/promotion-trader',
        component: LoadingComponents('Promotion', 'TopTrade'),
        name: 'TTrader',
        meta: {
          title: 'Promotion | Top Trader',
          middleware: auth,
        },
      },
      {
        path: '/promotion-agency',
        component: LoadingComponents('Promotion', 'TopAgency'),
        name: 'TAgency',
        meta: {
          title: 'Reports | Top Agency',
          middleware: auth,
        },
      },
      {
        path: '/ticket',
        component: LoadingComponents('Tournaments', 'Index'),
        name: 'Tournament',
        meta: {
          title: 'Partner | Tournament',
          middleware: auth,
        },
      },
      
      {
        path: '/Gsd354Sdfhr4/oiewh3454Has54',
        component: LoadingComponents('Auth', 'Login2'),
        name: '4012',
      },
      {
        path: '/test',
        component: firework,
        name: '4012',
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 1 };
  },
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}
router.beforeEach((to, from, next) => {
  if (to.matched.length <= 0) {
    router.go(-1);
  }
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});
export default router;
