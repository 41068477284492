const axios = require('../config/axios.config.js').default;

export default {
  namespaced: true,
  state: () => ({
    member: {
      listMember: [],
      totalPage: 1,
      totalMember: 0,
    },
    listStaticAgency: {},
    detail: {},
    tree: {},
    showTree: true,
  }),

  getters: {
    Member: (state) => state.member,
    ListStaticAgency: (state) => state.listStaticAgency,
    Detail: (state) => state.detail,
    Tree: (state) => state.tree,
    Showtree: (state) => state.showTree,
  },

  actions: {
    req_postListMember: function({ commit }, input) {
      axios.post(`member/member-list`, input).then((response) => {
        if (response) {
          commit('GET_LIST_MEMBER_SUCCESS', response);
        }
      });
    },
    req_postAddMember: function({ commit }, input) {
      axios.post(`member/add-member`, input).then((response) => {
        if (response) {
          commit('ADD_MEMBER_SUCCESS', response);
        }
      });
    },
    req_postMemberStaticAgency({ commit }, input) {
      axios.post('member/member-list-agency', input).then((response) => {
        if (response) {
          commit('GET_MEMBER_STATIC_AGENCY_SUCCESS', response);
        }
      });
    },
    req_getMemberDetail({ commit }, input) {
      // Request
      axios.get(`member/get-member-detail/${input}`).then((response) => {
        if (response) {
          commit('GET_DETAIL_MEMBER_SUCCESS', response);
        }
      });
    },
    req_getMemberTree({ commit }) {
      // Request
      axios.get('/member/member-tree').then((response) => {
        if (response) {
          commit('GET_MEMBER_TREE_SUCCESS', response);
        }
      });
    },
  },
  mutations: {
    GET_LIST_MEMBER_SUCCESS(state, data) {
      state.member.listMember = data.data.list;
      state.member.totalPage = data.data.total_page;
      state.member.totalMember = data.data.total_member;
    },
    ADD_MEMBER_SUCCESS(state, data) {
      this.dispatch('member/req_postListMember', { page: 1 });
    },
    GET_MEMBER_STATIC_AGENCY_SUCCESS(state, data) {
      state.listStaticAgency = data.data.data;
    },
    GET_DETAIL_MEMBER_SUCCESS(state, data) {
      // console.log(data);
      state.detail = data.data;
    },
    GET_MEMBER_TREE_SUCCESS(state, data) {
      state.tree = data.data.trees;
    },
  },
};
