const axios = require('../config/axios.config.js').default;

export default {
  namespaced: true,
  state: () => ({
    historyLogins: {},
    total: {},
    game: {},
    gameStatus: {},
    changePasswordStatus: {},
    info: {},
    topLeader: {},
    transaction: {},
    balance: {},
    checkKYC: {},
    listGame: [],
    agency: {},
    document: {},
    liquidPartner: [],
  }),

  getters: {
    Balance: (state) => state.balance,
    Transactions: (state) => state.transaction,
    HistoryLogins: (state) => state.historyLogins,
    Total: (state) => state.total,
    Game: (state) => state.game,
    Info: (state) => state.info,
    TopLeader: (state) => state.topLeader,
    CheckKYC: (state) => state.checkKYC,
    GameStatus: (state) => state.gameStatus,
    ChangePasswordStatus: (state) => state.changePasswordStatus,
    ListGame: (state) => state.listGame,
    Agency: (state) => state.agency,
    Document: (state) => state.document,
    LiquidPartner: (state) => state.liquidPartner,
  },

  actions: {
    req_getDashboard({ commit }, input) {
      axios.get(`auth/dashboard?page=${input}`).then((response) => {
        if (response) {
          commit('SET_DASHBOARD', response);
        }
      });
    },
    req_getListGame({ commit }) {
      axios.get('list-game').then((response) => {
        if (response) {
          commit('SET_LIST_GAME', response);
        }
      });
    },
    req_getAgency: function({ commit }) {
      axios.get(`game/agency`).then((response) => {
        if (response) {
          commit('SET_AGENCY', response);
        }
      });
    },
    req_buyAgency: function({ commit, dispatch }, input) {
      axios.post(`game/buy-agency`, input).then((response) => {
        if (response) {
          commit('BUY_AGENCY_SUCCESS', response.data.data);
          dispatch('req_getAgency');
        }
      });
    },
    req_getDocument: function({ commit }) {
      axios.get(`document`).then((response) => {
        if (response) {
          commit('SET_DOCUMENT', response);
        }
      });
    },
    req_postCooperationContact: function({ commit }, input) {
      axios.post(`cooperation-contact`, input).then((response) => {
        if (response) {
          commit('SEND_COOPERATION_CONTACT', response.data.data);
        }
      });
    },
    req_getLiquidPartner: function({ commit }) {
      axios.get(`liquid-partners`).then((response) => {
        if (response) {
          commit('GET_LIQUID_PARTMER', response.data);
        }
      });
    },
    // req_getLiquidPartner: function({ commit }, input) {
    //   axios
    //     .get(`liquid-partners`, { params: { test: input } })
    //     .then((response) => {
    //       if (response) {
    //         console.log(response);
    //         commit('GET_LIQUID_PARTMER', response.data);
    //       }
    //     });
    // },
  },
  mutations: {
    SET_DASHBOARD(state, data) {
      state.historyLogins = data.data.loginHistory;
      state.transaction = data.data.tranHistory.data;
      state.balance = data.data.balance;
      state.total.member = data.data.totalMember;
      state.total.sub = data.data.totalSub;
      state.game = data.data.url_game;
      state.gameStatus = data.data.game_status;
      state.info = data.data.info;
      state.topLeader = data.data.topLeader;
      state.checkKYC = data.data.checkKYC;
      state.changePasswordStatus = data.data.change_password;
    },
    SET_LIST_GAME(state, data) {
      state.listGame = data.data;
    },
    SET_AGENCY(state, data) {
      state.agency = data.data.Game;
    },
    BUY_AGENCY_SUCCESS(state, data) {
      // this.dispatch('dashboard/req_getAgency');
    },
    SET_DOCUMENT(state, data) {
      state.document = data.data;
    },
    SEND_COOPERATION_CONTACT() {},
    GET_LIQUID_PARTMER(state, data) {
      state.liquidPartner = data;
    },
  },
};
