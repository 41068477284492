<template>
  <div class="footer">
    <div class="footer-wrap">
      <div class="logo-group">
        <img src="~@/assets/images/logo.png" alt="" class="logo" />
      </div>
      <div class="box-1">
        <div class="footer__desc">
          {{ $t('footer.21') }}
        </div>
        <!-- <div class="v-line"></div>
        <div class="footer__entries">
          <a target="_blank" href="#">JOIN US</a><a href="#">HELP CENTER</a
          ><a href="#">USER AGREEMENT</a><a href="#">PRIVACY POLICY</a
          ><a href="#">APP</a><a href="#" target="_blank">FORUM</a
          ><a target="_blank" href="#">GAMBLE AWARE</a
          ><a target="_blank" href="#" class="border-none">NEWS</a>
        </div>
        <div class="contact-us">
          <div class="footer__contact">
            <div class="title ttu">CONTACT US</div>
            <div class="flex-wrap">
              <div class="flex-group">
                <div class="item">
                  <span class="name">Help:</span
                  ><a href="#" target="_blank">Help.game</a>
                </div>
              </div>
              <div class="flex-group-right">
                <div class="item flex flex-right">
                  <span class="name">Business:</span
                  ><a href="#">Business@game</a>
                </div>
              </div>
            </div>
          </div>
          <div class="footer__currencies">
            <div class="title ttu">Accepted currencies</div>
            <div class="currency-list">
              <div class="currency-item" v-for="i in 10" :key="i">
                <img src="~@/assets/images/home/coin.png" alt="" />
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- License -->
      <!-- <div class="footer_license">
        <div class="licenes-by">
          <a href="#" target="_blank"
            ><img src="~@/assets/images/footer/listen.png"
          /></a>
          <div class="licenes-desc">
            GAME 
          </div>
        </div>
        <div class="footer__cert">
          <a href="#" class="img-cgf" target="_blank"
            ><img src="~@/assets/images/footer/cryptocurrency.png"/></a
          ><a href="#" class="img-rng" target="_blank"
            ><img src="~@/assets/images/footer/analysis.png"/></a
          ><a><img src="~@/assets/images/footer/footer-text.svg"/></a
          ><a><img src="~@/assets/images/footer/adult.png"/></a>
        </div>
      </div> -->
    </div>
    <a href="#"
      ><button class="btn-top">
        <i class="fas fa-chevron-up"></i>{{ $t('footer.22') }}
      </button></a
    >
  </div>
</template>

<script>
  export default {};
</script>

<style scoped lang="scss">
  .footer {
    background-color: rgb(27, 29, 33, 0.8);
    padding: 48px 0px 48px 0px;
    position: relative;
    z-index: 10;
    .footer-wrap {
      width: 90%;
      margin: 0px auto;
      max-width: 1328px;
      padding: 0px 16px;
      line-height: 2;
      @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .logo-group {
        display: flex;
        align-items: center;
        .license-box {
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(153, 164, 176, 0.8);
          margin-left: 30px;
          margin-top: 10px;
          .license {
            width: 45px;
            height: auto;
          }
        }
        .logo {
          width: 160px;
          margin-bottom: 2px;
        }
      }
      .box-1 {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1399px) {
          flex-wrap: wrap;
        }
        @media (max-width: 991px) {
          flex-wrap: nowrap;
        }
        @media (max-width: 767px) {
          flex-wrap: wrap;
          justify-content: center;
        }
        .footer__desc {
          width: 100%;
          padding-right: 24px;
          font-size: 16px;
          line-height: 150%;
          color: rgba(153, 164, 176, 0.8);
          margin-top: 20px;
          @media (max-width: 767px) {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-right: 0px;
            text-align: center;
          }
        }
        .v-line {
          border-right: 1px solid rgba(84, 97, 106, 0.15);
          @media (max-width: 1199px) {
            display: none;
          }
          @media (max-width: 991px) {
            display: block;
          }
          @media (max-width: 767px) {
            display: none;
          }
        }
        .footer__entries {
          padding: 0px 24px 0px 48px;
          white-space: nowrap;
          line-height: 2.5;
          margin-right: 40px;
          @media (max-width: 1399px) {
            padding: 0px;
            margin-right: 0px;
            margin-top: 20px;
            margin-bottom: 20px;
          }
          @media (max-width: 991px) {
            margin: 0px 40px;
            padding: 0px 24px 0px 48px;
          }
          @media (max-width: 767px) {
            width: 100%;
            padding: 0px;
            margin: 20px 0px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
          a {
            display: block;
            font-size: 12px;
            color: rgb(192, 203, 213);
            text-transform: uppercase;
            text-decoration: none;
            @media (max-width: 767px) {
              margin: 5px 10px;
            }
          }
        }
        .contact-us {
          max-width: 560px;
          color: rgba(153, 164, 176, 0.8);
          @media (max-width: 767px) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
          }
          .footer__contact {
            @media (max-width: 767px) {
              text-align: center;
            }
            .title {
              font-size: 12px;
              text-transform: uppercase;
              @media (max-width: 767px) {
                font-size: 16px;
              }
            }
            .flex-wrap {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              @media (max-width: 420px) {
                justify-content: center;
              }
              .flex-group {
                margin-right: 6px;
                .item {
                  font-size: 12px;
                  -webkit-box-pack: start;
                  justify-content: flex-start;
                  -webkit-box-align: center;
                  align-items: center;
                  span {
                    margin-right: 4px;
                  }
                  a {
                    color: rgb(93, 160, 0);
                    text-decoration: none;
                  }
                }
              }
              .flex-group-right {
                margin-left: 60px;
                @media (max-width: 1799px) {
                  margin-left: 5px;
                }
                @media (max-width: 1599px) {
                  margin-left: 0px;
                }
                @media (max-width: 767px) {
                  margin-left: 20px;
                }
                @media (max-width: 420px) {
                  margin-left: 0px;
                }
                .item {
                  font-size: 12px;
                  -webkit-box-pack: start;
                  justify-content: flex-start;
                  -webkit-box-align: center;
                  align-items: center;
                  span {
                    margin-right: 4px;
                  }
                  a {
                    color: rgb(93, 160, 0);
                    text-decoration: none;
                  }
                }
              }
            }
          }
          .footer__currencies {
            margin-top: 24px;
            .title {
              font-size: 12px;
              text-transform: uppercase;
              @media (max-width: 767px) {
                font-size: 16px;
              }
            }
            .currency-list {
              margin-top: 10px;
              display: flex;
              flex-wrap: wrap;
              @media (max-width: 767px) {
                justify-content: center;
              }
              .currency-item {
                margin-right: 16px;
                margin-bottom: 12px;
                position: relative;
                img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
      }
      .footer_license {
        width: 100%;
        display: flex;
        border-top: 1px solid rgba(84, 97, 106, 0.15);
        border-bottom: 1px solid rgba(84, 97, 106, 0.15);
        border-left: none;
        border-right: none;
        padding: 20px 0px;
        margin-top: 30px;
        color: rgba(153, 164, 176, 0.8);
        @media (max-width: 1399px) {
          flex-wrap: wrap;
        }
        @media (max-width: 991px) {
          flex-wrap: nowrap;
        }
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
        .licenes-by {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          padding: 10px 0px;
          max-width: 660px;
          @media (max-width: 1399px) {
            margin-bottom: 30px;
          }
          @media (max-width: 991px) {
            margin-bottom: 0px;
          }
          @media (max-width: 767px) {
            flex-wrap: wrap;
            justify-content: center;
          }
          img {
            width: 54px;
            height: 51px;
            margin-right: 30px;
            vertical-align: top;
            @media (max-width: 767px) {
              margin-bottom: 20px;
            }
          }
          .licenes-desc {
            font-size: 13px;
            line-height: 1.4;
          }
        }
        .footer__cert {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          margin-left: 40px;
          padding-left: 50px;
          -webkit-box-pack: justify;
          justify-content: space-between;
          flex: 1 1 0%;
          border-left: 1px solid rgba(84, 97, 106, 0.15);
          @media (max-width: 1399px) {
            margin-left: 0px;
            padding-left: 0px;
          }
          @media (max-width: 991px) {
            margin-left: 40px;
            padding-left: 50px;
          }
          @media (max-width: 767px) {
            border-left: none;
            border-top: 1px solid rgba(84, 97, 106, 0.15);
            margin-left: 0px;
            padding-left: 0px;
            margin-top: 20px;
            padding-top: 20px;
          }
          a {
            margin: 0px 10px;
            img {
              width: 62px;
              height: 31px;
              &:nth-child(3) {
                width: 90px;
                height: 32px;
              }
              @media (max-width: 480px) {
                width: 45px;
                height: auto;
              }
            }
          }
        }
      }
    }
    .btn-top {
      position: absolute;
      bottom: 50px;
      right: 50px;
      background: rgba(53, 60, 66, 0.4);
      border: none;
      padding: 10px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      @media (max-width: 767px) {
        bottom: 0px;
        right: 10px;
      }
      svg {
        color: #7bc514;
        font-size: 12px;
      }
      a {
        text-decoration: none;
        color: #fff;
        font-size: 12px;
      }
    }
  }
</style>
