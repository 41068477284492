<template>
  <div>
    <b-modal :id="`modal-register`" hide-footer hide-header>
      <div class="login-header">
        <div class="has-close">
          <img src="~@/assets/images/logo.png" class="has-close-img" />
        </div>
        <button class="close-icon" @click="$bvModal.hide(`modal-register`)">
          <i class="fas fa-times"></i>
        </button>
        <div class="welcome">
          <div class="msg1">
            {{ $t('Login.103') }}
          </div>
          <div class="msg2">
            {{ $t('header.2') }}
          </div>
        </div>
      </div>
      <div class="form-login" style="transform: none">
        <!-- Register email, password -->
        <div class="login">
          <div class="box">
            <div class="input">
              <div class="input-label">{{ $t('Login.105') }}</div>
              <div class="input-control">
                <input
                  type="email"
                  :placeholder="$t('Login.105')"
                  value=""
                  v-model="formRegister.email"
                  required
                />
              </div>
            </div>
            <div class="input">
              <div class="input-label">
                <div style="flex: 1 1 0%">{{ $t('Login.106') }}</div>
              </div>
              <div class="input-control">
                <input
                  type="password"
                  :placeholder="$t('Login.106')"
                  value=""
                  v-model="formRegister.password"
                  required
                />
              </div>
            </div>
            <div class="input">
              <div class="input-label">
                <div style="flex: 1 1 0%">{{ $t('Transaction.88') }}</div>
              </div>
              <div class="input-control">
                <input
                  type="password"
                  :placeholder="$t('Transaction.88')"
                  value=""
                  v-model="passwordConfirm"
                  required
                />
              </div>
            </div>
            <div class="input">
              <div class="input-label">
                <div style="flex: 1 1 0%">{{ $t('Register.111') }}</div>
              </div>
              <div class="input-control">
                <input
                  :placeholder="$t('Register.111')"
                  v-model.trim="formRegister.sponsor"
                  required
                />
              </div>
            </div>
            <!-- <div class="input">
              <div class="input-label">
                <div style="flex: 1 1 0%">
                  Mã sòng bạc (không bắt buộc) (Optional)
                </div>
              </div>
              <div class="input-control">
                <input
                  type="text"
                  placeholder="Mã sòng bạc không bắt buộc"
                  value=""
                />
              </div>
            </div> -->
          </div>
          <hr />
          <!-- <div class="argument-check">
            <div class="sc-giYglK eCErzb checkbox">
              <b-form-checkbox></b-form-checkbox>
            </div>
            <div class="label">
              Tôi đồng ý với <a class="argument">điều khoản sử dụng</a>, và xác
              nhận rằng tôi đủ 18 tuổi!
            </div>
          </div> -->
          <div class="buttons">
            <button class="button button-big">
              <div class="button-inner" @click="handleClickShowLogin()">
                <i class="fas fa-chevron-left"></i>{{ $t('header.1') }}
              </div></button
            ><button class="button button-big signup" @click="onRegister">
              <div class="button-inner">
                <span>{{ $t('header.2') }}</span>
              </div>
            </button>
          </div>
        </div>
        <!-- Register metamask -->
        <div id="other-login" class="login-fotter" v-if="false">
          <div class="box-title">{{ $t('Register.112') }}</div>
          <div class="other-group">
            <button id="gg_login" type="button" title="google">
              <img src="~@/assets/images/login/gg.png" alt="" /></button
            ><button id="fb_login" type="button" title="facebook">
              <img src="~@/assets/images/leftsidebar/fb.svg" alt="" /></button
            ><button id="tg_login" type="button" title="telegram">
              <img src="~@/assets/images/leftsidebar/tele.svg" alt="" />
            </button>
            <div class="line"></div>
            <button type="button">
              <img src="~@/assets/images/leftsidebar/git.svg" alt="" />
            </button>
          </div>
        </div>
        <div id="other-login" class="login-fotter">
          <p class="text-warning font-weight-bold">{{ $t('Message.274') }}</p>
          <div class="other-group">
            <button type="button" @click="onRegisterMetamask">
              {{ $t('Register.113') }}
              <img src="~@/assets/images/icon/metamask.svg" alt="" />
              {{ $t('Message.270') }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import Web3 from 'web3';
  import { mapGetters, mapMutations } from 'vuex';

  const axios = require('@/store/config/axios.config').default;

  export default {
    name: 'Register',
    props: ['type'],
    components: {},
    data() {
      return {
        formRegister: {
          email: '',
          password: '',
          sponsor: '',
          token: '',
        },
        passwordConfirm: '',
      };
    },
    methods: {
      ...mapMutations(['onLoad', 'outLoad']),
      handleClickShowLogin() {
        this.$emit('showLogin');
      },
      onRegister() {
        if (!this.formRegister.password) {
          this.$toastr.e(this.$t('Message.251'), this.$t('Message.238'));
          return;
        }
        if (this.passwordConfirm !== this.formRegister.password) {
          this.passwordConfirm = '';
          this.formRegister.password = '';
          this.$toastr.e(this.$t('Message.252'), this.$t('Message.238'));
          return;
        }
        this.$store.dispatch('auth/req_postRegister', this.formRegister);
      },
      async onRegisterMetamask() {
        try {
          const checkWallet = await this.checkWallet();
          if (!checkWallet) {
            return;
          }
          // let sponsor = 456319;
          let sponsor = '';
          if (this.$route.query.sponsor) {
            sponsor = this.$route.query.sponsor;
          } else {
            sponsor = this.formRegister.sponsor;
          }
          const address = Web3.utils.toChecksumAddress(
            await this.$store.state.wallet.addr
          );
          const data = await axios.post('auth/address/register', {
            address: address,
            sponsor,
          });
          if (!data) {
            this.$store.commit('auth/REGISTER_SUCCESS');
            return;
          }
          if (data && data.data && data.data.message) {
            const signature = data.data.message;
            const txData = await window.ethereum.request({
              method: 'personal_sign',
              params: [address, signature],
            });
            // eslint-disable-next-line no-shadow
            const data2 = await axios.post('auth/address/register', {
              address: address,
              signature: txData,
              message: data.data.message,
              sponsor,
            });
            // console.log(data2);
            if (data2 && data2.data) {
              this.$store.commit('auth/REGISTER_SUCCESS');
            }
          }
        } catch (e) {
          this.$toastr.e(e.message, this.$t('Message.240'));
          this.outLoad();
        }
      },
    },
    mounted() {
      if (this.$route.query.sponsor) {
        this.formRegister.sponsor = this.$route.query.sponsor;
        // console.log(this.formRegister.sponsor);
      }
    },
    async created() {
      if (window.ethereum) {
        // eslint-disable-next-line no-unused-vars
        window.ethereum.on('accountsChanged', async (accounts) => {
          window.location.reload();
        });
        // eslint-disable-next-line no-unused-vars
        window.ethereum.on('chainChanged', async (chainId) => {
          window.location.reload();
        });
      }
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        // eslint-disable-next-line default-case
        switch (mutation.type) {
          case 'core/SUCCESS_MESSANGE':
            this.passwordConfirm = '';
            this.formRegister.email = '';
            this.formRegister.password = '';
            this.formRegister.sponsor = '';
            break;
          case 'core/ERROR_MESSANGE':
            this.passwordConfirm = '';
            this.formRegister.password = '';
            break;
        }
      });
    },
    beforeDestroy() {
      this.unsubscribe();
    },
  };
</script>
<style lang="scss">
  #modal-register {
    .modal-dialog {
      height: 100%;
      @media (max-width: 575px) {
        margin: 0px;
      }
    }
    .modal-content {
      border-radius: 1.25rem;
      // height: auto;
      &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
        @media (max-width: 375px) {
          background-color: rgba(0, 0, 0, 0.247);
        }
      }
      .modal-body {
        padding: 0px;
        margin-top: 0px;
      }
    }
  }
</style>
<style scoped lang="scss">
  .login-header {
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/login/bg-login.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 0px;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.521);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.473);
    .has-close {
      .has-close-img {
        width: auto;
        height: 5rem;
      }
    }
    .close-icon {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 11;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 3.75rem;
      height: 3.75rem;
      background: none;
      border: none;
      svg {
        width: 1.4em;
        height: 1.4em;
        transition: transform 0.5s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
        cursor: pointer;
        font-size: 0.75rem;
        color: rgb(255, 255, 255);
      }
    }
    .welcome {
      margin: 20px 0px;
      z-index: 1;
      color: rgb(245, 246, 247);
      padding: 0px 1em;
      @media (max-width: 575px) {
        height: 5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0px;
      }
      .msg1 {
        font-size: 1.5rem;
        font-weight: bold;
        width: 100%;
        text-align: center;
        line-height: 1.2;
        @media (max-width: 575px) {
          font-size: 1rem;
          width: 90%;
          line-height: 1.5;
        }
      }
      .msg2 {
        font-size: 1rem;
        font-weight: bold;
        width: 100%;
        text-align: center;
        line-height: 1.2;
        margin-top: 20px;
        @media (max-width: 575px) {
          font-size: 1rem;
          width: 11.375rem;
          line-height: 1.2;
          margin-top: 10px;
        }
      }
      img {
        height: 13.75rem;
        position: absolute;
        right: 20px;
        top: 3rem;
        z-index: 0;
        @media (max-width: 575px) {
          height: 10rem;
        }
        @media (max-width: 375px) {
          height: 9rem;
        }
      }
    }
  }
  .form-login {
    width: 100%;
    height: auto;
    background-color: rgb(23, 24, 27);
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    position: relative;
    z-index: 2;
    &::-webkit-scrollbar {
      width: 0px !important;
    }
    .login {
      background-color: rgb(30, 32, 36);
      border-top-left-radius: 1.25rem;
      border-top-right-radius: 1.25rem;
      height: auto;
      .box {
        padding: 1.5rem 1.25rem;
        @media (max-width: 575px) {
          padding: 1rem 1.25rem;
        }
        .input {
          &:nth-child(1) {
            margin-top: 0.25rem;
          }
          margin-top: 1em;
          .input-label {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            line-height: 1em;
            height: 1.25rem;
            margin: 0px 0.75rem 0.375rem;
            color: rgba(153, 164, 176, 0.6);
            @media (max-width: 575px) {
              font-size: 14px;
            }
          }
        }
        .input-control {
          position: relative;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          border-radius: 1.25rem;
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(45, 48, 53, 0.5);
          height: 3.5rem;
          opacity: 1;
          @media (max-width: 575px) {
            height: 2.5rem;
            font-size: 14px;
          }
          input {
            width: 100%;
            height: 100%;
            padding: 20px;
            border: none;
            background: none;
            color: rgb(245, 246, 247);
            border-radius: 1.25rem;
            &:focus-visible {
              outline: none;
              border: 1px solid rgb(93, 160, 0);
            }
          }
        }
      }
    }
    hr {
      height: 1px;
      margin: 0px;
      border: none;
      background-color: rgba(62, 72, 79, 0.3);
    }
    .argument-check {
      display: flex;
      padding: 1.5rem 1.25rem;
      @media (max-width: 575px) {
        padding: 1.5rem 1.25rem 0px 1.25rem;
      }
      .label {
        color: rgba(153, 164, 176, 0.6);
        @media (max-width: 575px) {
          font-size: 14px;
        }
        a {
          color: rgb(93, 160, 0);
          text-decoration: none;
        }
      }
    }
    .buttons {
      padding: 1.25rem 2.5rem;
      display: flex;
      .button {
        border: none;
        &:nth-child(1) {
          color: rgb(245, 246, 247);
          background-color: rgb(49, 52, 60);
          flex: 1 1 0%;
          height: 3.625rem;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          font-weight: bold;
          cursor: pointer;
          transition: transform 0.2s;
          display: block;
          width: 100%;
          border-radius: 6.25rem;
          margin-right: 0.625rem;
          @media (max-width: 575px) {
            height: 2.5rem;
            font-size: 14px;
          }
        }
        &:nth-child(2) {
          width: 15rem;
          flex: 0 0 auto;
          height: 3.625rem;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          transition: transform 0.2s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
          border-radius: 6.25rem;
          display: block;
          @media (max-width: 575px) {
            width: 13rem;
            height: 2.5rem;
            font-size: 14px;
          }
          @media (max-width: 480px) {
            width: 11rem;
          }
          @media (max-width: 400px) {
            width: 50%;
          }
        }
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          svg {
            color: rgb(245, 246, 247);
            font-weight: bold;
            cursor: pointer;
            margin-right: 10px;
            @media (max-width: 375px) {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .login-fotter {
    padding: 1rem 2.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    .box-title {
      text-align: center;
      width: 100%;
      line-height: 1;
      margin-bottom: 0.875rem;
      color: rgba(153, 164, 176, 0.6);
    }
    .other-group {
      border-radius: 1.75rem;
      background-color: #ff9800b3;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      button {
        display: flex;
        height: 3.5rem;
        width: 100%;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        border: none;
        background: none;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: bold;
        img {
          margin: 0px 5px;
          width: 30px;
          height: auto;
        }
      }
      .line {
        width: 1px;
        margin: 0.875rem 0px;
        background-color: rgb(45, 49, 55);
      }
    }
  }
</style>
