const axios = require('../config/axios.config.js').default;

export default {
  namespaced: true,
  state: () => ({
    listTournament: {},
    tournamentReward: {},
  }),

  getters: {
    ListTournament: (state) => state.listTournament,
    TournamentReward: (state) => state.tournamentReward,
  },

  actions: {
    req_getListTournament({ commit }, input) {
      axios.get(`tournament/list`, { params: input }).then((response) => {
        if (response) {
          commit('GET_TOURNAMENT_SUCCESS', response);
        }
      });
    },
    req_postBuyTournament({ commit }, input) {
      axios.post(`tournament/buy`, input).then((response) => {
        if (response) {
          commit('BUY_TOURNAMENT_SUCCESS', response);
        }
      });
    },
  },
  mutations: {
    GET_TOURNAMENT_SUCCESS(state, data) {
      state.listTournament = data.data;
    },
    BUY_TOURNAMENT_SUCCESS(state, data) {
      state.tournamentReward = data.data;
    },
  },
};
