<template>
  <div>
    <b-modal :id="`modal-info-${type}`" hide-footer hide-header>
      <div class="has-close">{{ $t('InforUser.34') }}</div>
      <button class="close-icon" @click="onHideModalUserInfo">
        <i class="fas fa-times"></i>
      </button>
      <div class="userInfo">
        <div class="avatar-box">
          <img class="avatar" src="~@/assets/images/christmas/snowman.png" />
        </div>
        <div class="name-box">
          <div class="user-name">
            {{ $t('InforUser.35') }}:
            {{ infoUser.email ? infoUser.email : $t('InforUser.38') }}
          </div>
          <div class="id">{{ $t('InforUser.36') }}: {{ infoUser.id }}</div>
          <div class="id">
            {{ $t('InforUser.37') }}:
            {{
              InfoMetamask.address ? InfoMetamask.address : $t('InforUser.38')
            }}
          </div>
        </div>
        <div class="button-box">
          <button
            class="button button-normal"
            @click="action = 1"
            v-if="infoUser.statusPassword"
          >
            <div class="button-inner"> {{ $t('InforUser.39') }}</div>
          </button>
          <button
            class="button button-normal"
            @click="action = 5"
            v-if="!infoUser.statusPassword && infoUser.email"
          >
            <div class="button-inner">{{ $t('InforUser.40') }}</div>
          </button>
          <button
            class="button button-normal"
            @click="action = 6"
            v-if="!infoUser.email"
          >
            <div class="button-inner">{{ $t('InforUser.41') }}</div>
          </button>
          <button class="button2 button-normal" @click="showFormAuth">
            <div class="button-inner">
              {{ Auth.enable ? $t('InforUser.42') : $t('InforUser.43') }}
            </div>
          </button>
          <button class="button3 button-normal" @click="onLogout">
            <div class="button-inner">{{ $t('Message.261') }}</div>
          </button>
          <button
            class="button4 button-normal"
            @click="updateWallet"
            v-if="!InfoMetamask.connectAddressMetamask"
          >
            <div class="button-inner">{{ $t('InforUser.44') }}</div>
          </button>
          <!-- <button
            class="button4 button-normal"
            @click="
              connectMeta = !connectMeta;
              action = 3;
            "
            v-if="InfoMetamask.connectAddressMetamask == false"
          >
            <div class="button-inner">CONNECT ADDRESS</div>
          </button>
          <vue-metamask
            v-if="connectMeta || statusDisConnectMeta == true"
            @onComplete="onComplete"
          />
          <button
            class="button4 button-normal"
            @click="disConnectMeta()"
            v-if="InfoMetamask.connectAddressMetamask == true"
          >
            <div class="button-inner">DISCONNECT ADDRESS</div>
          </button> -->
        </div>
        <!-- Change pass -->
        <div class="changePass" v-if="action === 1">
          <b-form @submit.prevent="onChangePass">
            <p>{{ $t('InforUser.45') }}</p>
            <b-form-input
              type="password"
              v-model="formChangePass.passwordCurrent"
              :placeholder="$t('InforUser.45')"
              required
            ></b-form-input>
            <p>{{ $t('InforUser.46') }}</p>
            <b-form-input
              type="password"
              v-model="formChangePass.password"
              :placeholder="$t('InforUser.46')"
              required
            ></b-form-input>
            <p>{{ $t('InforUser.47') }}</p>
            <b-form-input
              type="password"
              v-model="formChangePass.passwordConfirm"
              :placeholder="$t('InforUser.47')"
              required
            ></b-form-input>
            <button class="button button-normal" type="submit">
              {{ $t('InforUser.39') }}
            </button>
          </b-form>
        </div>
        <!-- Update Email -->
        <div class="changePass" v-if="action === 6">
          <b-form @submit.prevent="onUpdateEmail">
            <p>{{ $t('InforUser.35') }}</p>
            <b-form-input
              type="text"
              v-model="formUpdateEmail.email"
              :placeholder="$t('InforUser.35')"
              required
            ></b-form-input>
            <button class="button button-normal" type="submit">
              {{ $t('InforUser.41') }}
            </button>
          </b-form>
        </div>
        <!-- updatePass -->
        <div class="changePass" v-if="action === 5">
          <b-form @submit.prevent="onUpdatePass">
            <p class="text-center text-uppercase font-weight-bold">
              {{ $t('InforUser.48') }}
            </p>
            <p>{{ $t('InforUser.35') }}</p>
            <b-form-input
              type="text"
              v-model="infoUser.email"
              readonly
            ></b-form-input>
            <p>{{ $t('InforUser.45') }}</p>
            <b-form-input
              type="password"
              v-model="formUpdatePass.password"
              :placeholder="$t('InforUser.45')"
              required
            ></b-form-input>
            <p>{{ $t('InforUser.47') }}</p>
            <b-form-input
              type="password"
              v-model="formUpdatePass.re_password"
              :placeholder="$t('InforUser.47')"
              required
            ></b-form-input>
            <button class="button button-normal" type="submit">
              {{ $t('InforUser.40') }}
            </button>
          </b-form>
        </div>
        <!-- Authentication -->
        <div class="authentication" v-if="action === 2">
          <template v-if="!Auth.enable">
            <div class="google-step-summary-top">
              {{ $t('GgAuthen.24') }}
              <a
                target="_blank"
                href="https://support.google.com/accounts/answer/1066447?hl=en&amp;rd=1"
                >{{ $t('GgAuthen.25') }}</a
              >. {{ $t('GgAuthen.26') }}.
            </div>
            <div class="google-step-summary-top two">
              {{ $t('GgAuthen.27') }}.
            </div>
            <div class="qrcode-warp">
              <img :src="Auth.qr" alt="qrcode" />
            </div>
            <div class="copy-input">
              <div class="input">
                <div class="input-label">{{ $t('GgAuthen.28') }}</div>
                <div class="input-control">
                  <input
                    type="text"
                    readonly=""
                    value=""
                    v-model="Auth.secret"
                  /><button class="button button-normal copy-button">
                    <div
                      class="button-inner"
                      v-clipboard:copy="Auth.secret"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                    >
                      <i class="fas fa-copy"></i>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <p class="twofa-alert">
              {{ $t('GgAuthen.30') }}.
            </p>
          </template>
          <form @submit.prevent="onConfirmAuth">
            <div class="codes">
              <p>{{ $t('GgAuthen.31') }}</p>
              <b-form-input
                type="text"
                :placeholder="$t('GgAuthen.31')"
                v-model="authCode"
                required
              ></b-form-input>
            </div>
            <button class="button button-normal" type="submit">
              <div class="button-inner">
                {{ Auth.enable ? $t('GgAuthen.32') : $t('GgAuthen.33') }}
              </div>
            </button>
          </form>
        </div>
        <!-- Connect address metamask -->
        <div class="metamask-confirm" v-if="action === 3">
          <b-form @submit.prevent="onConfirmAddress">
            <p>{{ $t('GgAuthen.50') }}</p>
            <b-form-input
              type="text"
              :placeholder="$t('GgAuthen.50')"
              v-model="metamask.address"
              readonly
            ></b-form-input>
            <p>{{ $t('GgAuthen.51') }}</p>
            <b-form-input
              type="text"
              :placeholder="$t('GgAuthen.51')"
              v-model="metamask.otp"
              required
            ></b-form-input>
            <button class="button button-normal" type="submit">{{ $t('GgAuthen.52') }}</button>
          </b-form>
        </div>
        <!-- DisConnect address metamask -->
        <div class="metamask-disconfirm" v-if="action === 4">
          <b-form @submit.prevent="onDisConfirmAddress">
            <p>{{ $t('GgAuthen.50') }}</p>
            <b-form-input
              type="text"
              :placeholder="$t('GgAuthen.50')"
              v-model="metamask.address"
              readonly
            ></b-form-input>
            <p>{{ $t('GgAuthen.51') }}</p>
            <b-form-input
              type="text"
              :placeholder="$t('GgAuthen.51')"
              v-model="metamask.otp"
              required
            ></b-form-input>
            <button class="button button-normal" type="submit">
              {{ $t('GgAuthen.53') }}
            </button>
          </b-form>
        </div>
      </div>
    </b-modal>
    <modalCopy :content="content" type="infoUser" />
  </div>
</template>

<script>
  import Web3 from 'web3';
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import VueMetamask from 'vue-metamask';
  import modalCopy from './PopupCopy.vue';

  const axios = require('@/store/config/axios.config').default;

  export default {
    components: { VueMetamask, modalCopy },
    props: ['infoUser', 'type'],
    data() {
      return {
        action: 0,
        formChangePass: {
          passwordCurrent: '',
          password: '',
          passwordConfirm: '',
        },
        authCode: '',
        connectMeta: false,
        statusDisConnectMeta: false,
        metamask: {
          address: '',
          otp: '',
        },
        formUpdatePass: {
          password: '',
          re_password: '',
        },
        formUpdateEmail: {
          email: '',
        },
        showFormMetaMaskConfirm: false,
        content: '',
        bnbNetwork: {
          // testnet
          //   chainId: "0x61",
          //   chainName: "Smart Chain - Testnet",
          //   nativeCurrency: {
          //     name: "Binance Coin",
          //     symbol: "BNB",
          //     decimals: 18,
          //   },
          //   rpcUrls: ["https://data-seed-prebsc-1-s2.binance.org:8545/"],
          //   blockExplorerUrls: ["https://testnet.bscscan.com"],

          // mainnet
          chainId: '0x38',
          chainName: 'Smart Chain',
          nativeCurrency: {
            name: 'Binance Coin',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls: ['https://bsc-dataseed.binance.org/'],
          blockExplorerUrls: ['https://bscscan.com'],
        },
      };
    },
    computed: {
      ...mapState(['isLogin']),
      ...mapGetters({
        Auth: 'auth/Auth',
        InfoMetamask: 'auth/InfoMeta',
      }),
    },
    methods: {
      ...mapMutations(['onLoad', 'outLoad']),
      onLogout() {
        this.$store.commit('logout');
        this.$store.commit('auth/LOGOUT_SUCCESS');
        window.location.href = '/';
      },
      onChangePass() {
        this.$store.dispatch('auth/req_changePassword', this.formChangePass);
        this.formChangePass = {
          ...{
            passwordCurrent: '',
            password: '',
            passwordConfirm: '',
          },
        };
      },
      showFormAuth() {
        this.action = 2;
        this.$store.dispatch('auth/req_getAuth');
      },
      async onConfirmAuth() {
        this.onLoad();
        const token = await this.genarateCaptChaV3('authentication');
        if (!token) {
          this.$toastr.e(
            this.$t('Message.242'),
            this.$t('Message.243')
          );
          return;
        }
        if (this.authCode !== '') {
          this.$store.dispatch('auth/req_changeAuth', {
            authCode: this.authCode,
            secret: this.Auth.secret,
            token: token,
          });
          this.$store.dispatch('auth/req_getAuth');
          this.action = 0;
        }
        this.$store.dispatch('auth/req_getAuth');
      },
      onCopy(e) {
        this.content = e.text;
        this.$bvModal.show('modal-copy-infoUser');
        this.$bvModal.hide('modal-info');
      },
      onError: function() {
        alert('Failed to copy texts');
      },
      onComplete(data) {
        this.metamask.address = data.metaMaskAddress;
        if (data.metaMaskAddress && this.statusDisConnectMeta == false) {
          this.action = 3;
        }
        if (data.metaMaskAddress && this.statusDisConnectMeta == true) {
          this.action = 4;
        }
      },
      onDisConfirmAddress() {
        this.$store.dispatch('auth/req_disConfirmConnectWallet', {
          address: this.metamask.address,
          otp: this.metamask.otp,
          // token: token
        });
        this.action = 0;
        this.metamask.otp = '';
        //window.location.reload();
      },
      async onConfirmAddress() {
        var address;
        const status = await this.onConnect();
        if (status) {
          address = await window.ethereum.request({
            method: 'eth_requestAccounts',
          });
          this.metamask.address = address[0];
        }
        this.$store.dispatch('auth/req_confirmConnectWallet', {
          address: this.metamask.address,
          otp: this.metamask.otp,
          // token: token
        });
        this.action = 0;
        this.metamask.otp = '';
        //window.location.reload();
      },
      disConnectMeta() {
        this.connectMeta = false;
        this.statusDisConnectMeta = true;
        this.action = 4;
      },
      async onConnect() {
        if (!window.ethereum) {
          this.$store.commit(
            'core/ERROR_MESSANGE',
            'Please Install Metamask To Join !'
          );
          return false;
        }
        var addrs = [];
        try {
          addrs = await window.ethereum.request({
            method: 'eth_requestAccounts',
          });
        } catch (error) {
          if (
            error.message ==
            'Already processing eth_requestAccounts. Please wait.'
          ) {
            this.$store.commit(
              'core/ERROR_MESSANGE',
              'Please Connect Metamask To Join !'
            );
            return false;
          }
          this.$store.commit('core/ERROR_MESSANGE', error.message);
          return false;
        }
        if (window.ethereum.chainId == this.bnbNetwork.chainId) {
          // if (window.ethereum.chainId == 0x38) {
          return true;
        }
        this.info.address = addrs[0];
        const params = this.bnbNetwork;
        window.ethereum
          .request({ method: 'wallet_addEthereumChain', params: [params] })
          .then(() => {
            this.$store.commit(
              'core/SUCCESS_MESSANGE',
              'Connect Metamask Successfully!'
            );
          })
          .catch((error) => {
            this.$store.commit(
              'core/ERROR_MESSANGE',
              'Please switch To The  Binance Smartchain Network!'
            );
            window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x61' }],
            });
          });
      },
      onHideModalUserInfo() {
        this.$bvModal.hide(`modal-info-${this.type}`);
        this.action = 0;
        this.formChangePass = {
          ...{
            passwordCurrent: '',
            password: '',
            passwordConfirm: '',
          },
        };
        this.metamask = {
          ...{
            address: '',
            otp: '',
          },
        };
        this.formUpdatePass = {
          ...{
            password: '',
            re_password: '',
          },
        };
        this.formUpdateEmail = {
          ...{
            email: '',
          },
        };
        this.authCode = '';
      },
      async updateWallet() {
        try {
          const checkWallet = await this.checkWallet();
          if (!checkWallet) {
            return;
          }
          const address = Web3.utils.toChecksumAddress(
            await this.$store.state.wallet.addr
          );
          const data = await axios.post('auth/address/update-address', {
            address: address,
          });
          if (data && data.data) {
            const signature = data.data.message;
            const txData = await window.ethereum.request({
              method: 'personal_sign',
              params: [address, signature],
            });
            // eslint-disable-next-line no-shadow
            const data2 = await axios.post('auth/address/update-address', {
              address: address,
              signature: txData,
              message: data.data.message,
            });
            // console.log(data2);
            if (data2 && data2.data) {
              this.$store.dispatch('auth/req_getInfoMetamask');
              this.$store.dispatch('dashboard/req_getDashboard', 1);
            }
          }
        } catch (e) {
          this.$toastr.e(e.message, this.$t('Message.240'));
          this.outLoad();
        }
      },
      onUpdatePass() {
        this.$store.dispatch('auth/req_updatePassword', this.formUpdatePass);
        this.formUpdatePass = {
          ...{
            password: '',
            re_password: '',
          },
        };
      },
      onUpdateEmail() {
        this.$store.dispatch('auth/req_updateEmail', this.formUpdateEmail);
        this.formUpdateEmail = {
          ...{
            email: '',
          },
        };
      },
    },
    created() {
      if (this.isLogin) {
        this.$store.dispatch('auth/req_getInfoMetamask');
      }
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        // eslint-disable-next-line default-case
        switch (mutation.type) {
          case 'auth/UPDATE_EMAIL_SUCCESS':
            this.$store.dispatch('dashboard/req_getDashboard', 1);
            this.action = 0;
            break;
          case 'auth/UPDATE_PASSWORD_SUCCESS':
            this.$store.dispatch('dashboard/req_getDashboard', 1);
            this.action = 0;
            break;
        }
      });
    },
    beforeDestroy() {
      this.unsubscribe();
    },
  };
</script>

<style lang="scss">
  .modal {
    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px;
    }
  }
  .modal-open {
    padding-right: 0px !important;
  }
  #modal-info-info {
    &.modal {
      display: flex !important;
      justify-content: center;
      align-items: flex-start;
      padding-left: 0px !important;
      &::-webkit-scrollbar {
        width: 0px !important;
        height: 0px;
      }
      .modal-content {
        height: auto;
        border-radius: 1.25rem;
        position: relative;
        background-color: rgb(23, 24, 27);
        padding: 1.25rem;
        @media (max-width: 480px) {
          font-size: 14px;
          padding: 0rem;
        }
      }
    }
  }
  #modal-info-updateInfo {
    &.modal {
      display: flex !important;
      justify-content: center;
      align-items: flex-start;
      padding-left: 0px !important;
      &::-webkit-scrollbar {
        width: 0px !important;
        height: 0px;
      }
      .modal-content {
        height: auto;
        border-radius: 1.25rem;
        position: relative;
        background-color: rgb(23, 24, 27);
        padding: 1.25rem;
        @media (max-width: 480px) {
          font-size: 14px;
          padding: 0rem;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  #modal-info-info {
    .has-close {
      font-size: 1rem;
      height: 2rem;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      font-weight: bold;
      color: #fff;
    }
    .close-icon {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 11;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 3.75rem;
      height: 3.75rem;
      border: none;
      background: none;
      color: rgba(153, 164, 176, 0.8);
    }
    .userInfo {
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: center;
      .avatar-box {
        .avatar {
          width: 70px;
          height: 70px;
          border: 2px solid rgb(70, 70, 70);
          border-radius: 50%;
          cursor: pointer;
          vertical-align: middle;
        }
      }
      .name-box {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        margin-top: 0.625rem;
        color: rgb(245, 246, 247);
        font-weight: 600;
        .user-name {
          font-size: 1.25rem;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
        }
        .id {
          font-size: 1.25rem;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          word-break: break-all;
          text-align: center;
        }
      }
      .button-box {
        display: flex;
        justify-content: space-between;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        .button {
          display: block;
          width: 48%;
          height: 3rem;
          margin: 1.25rem 10px 0.5rem 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          border-radius: 6.25rem;
          border: none;
          font-size: 14px;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
        .button2 {
          display: block;
          width: 48%;
          height: 3rem;
          margin: 1.25rem 0px 0.5rem 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #581ac4;
          background-image: conic-gradient(from 1turn, #581ac4, #773cfd);
          border-radius: 6.25rem;
          font-weight: bold;
          cursor: pointer;
          border: none;
          font-size: 14px;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
        .button3 {
          display: block;
          width: 48%;
          height: 3rem;
          margin: 0rem 10px 1rem 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #581ac4;
          background-image: conic-gradient(from 1turn, #c43c1a, #fd3c3c);
          border-radius: 6.25rem;
          font-weight: bold;
          cursor: pointer;
          border: none;
          font-size: 14px;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
        .button4 {
          display: block;
          width: 48%;
          height: 3rem;
          margin: 0rem 0px 1rem 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #581ac4;
          background-image: conic-gradient(from 1turn, #c48e1a, #fda33c);
          border-radius: 6.25rem;
          font-weight: bold;
          cursor: pointer;
          border: none;
          font-size: 14px;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
      .changePass {
        width: 100%;
        p {
          color: rgba(153, 164, 176, 0.8);
          margin-bottom: 5px;
          margin-top: 20px;
        }
        .form-control {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(123, 197, 20);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
            color: rgb(123, 197, 20);
          }
        }
        button {
          display: block;
          width: 11.25rem;
          height: 3rem;
          margin: 1.25rem auto 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          border-radius: 6.25rem;
          border: none;
          margin-bottom: 30px;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
      .authentication {
        border-radius: 20px;
        background-color: rgb(30, 32, 36);
        padding: 1.25rem 1.25rem 0px;
        padding-bottom: 20px;
        flex: 1 1 0%;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        touch-action: pan-y;
        color: rgba(153, 164, 176, 0.6);
        max-height: 500px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          background-color: transparent;
          border-radius: 5px;
        }
        .google-step-summary-top {
          padding: 1rem 1.25rem;
          border-radius: 0.625rem;
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(45, 48, 53, 0.5);
          font-size: 0.75rem;
          line-height: 1.25rem;
          a {
            text-decoration: underline;
            margin-left: 0.25rem;
            color: rgb(93, 160, 0);
          }
          &.two {
            background-color: transparent;
            border: none;
            padding: 0px 1.25rem;
            margin-top: 0.75rem;
          }
        }
        .qrcode-warp {
          margin-top: 0.75rem;
          background: rgb(245, 246, 247);
          width: 8.625rem;
          height: 8.625rem;
          margin: 1.25rem auto 0px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          border-radius: 0.75rem;
          font-size: 0px;
          padding: 0.3125rem;
          img {
            width: 8rem;
            height: 8rem;
          }
        }
        .copy-input {
          margin-top: 0.75rem;
          .input {
            margin-top: 1.5rem;
            .input-label {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              line-height: 1em;
              height: 1.25rem;
              margin: 0px 1.125rem 0.375rem;
              color: rgba(153, 164, 176, 0.6);
            }
            .input-control {
              height: 3.5rem;
              position: relative;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              border: 1px solid rgb(45, 48, 53);
              background-color: rgba(45, 48, 53, 0.5);
              opacity: 1;
              border-radius: 1.5rem;
              padding: 0px 1.375rem;
              input {
                flex: 1 1 0%;
                width: 100%;
                height: 100%;
                min-width: 1rem;
                padding: 0px;
                border: none;
                background-color: transparent;
                color: rgb(245, 246, 247);
              }
              .copy-button {
                width: 2.5rem;
                height: 2.5rem;
                margin-top: 0.125rem;
                margin-right: -1.25rem;
                font-weight: bold;
                cursor: pointer;
                border-radius: 6.25rem;
                color: rgba(153, 164, 176, 0.8);
                border: none;
                background: none;
              }
            }
          }
        }
        .twofa-alert {
          margin: 1.125rem 0px 0px;
          padding: 0px 1.25rem 2.25rem;
          border-bottom: 1px solid rgba(176, 179, 191, 0.1);
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
        .codes {
          margin-top: 1.5rem;
          p {
            margin: 0px;
            height: 1.25rem;
            line-height: 1.25rem;
            text-align: center;
          }
          .form-control {
            margin-top: 20px;
            border: 1px solid rgb(45, 48, 53);
            background-color: rgba(45, 48, 53, 0.5);
            border-left: none;
            border-radius: 15px;
            color: rgb(123, 197, 20);
            &:focus {
              background: none;
              border: 1px solid rgb(123, 197, 20);
              outline: none;
              box-shadow: none;
            }
          }
        }
        button {
          display: block;
          width: 11.25rem;
          height: 3rem;
          margin: 1.25rem auto 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          border-radius: 6.25rem;
          border: none;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
      .metamask-confirm {
        width: 100%;
        p {
          color: rgba(153, 164, 176, 0.8);
          margin-bottom: 5px;
          margin-top: 20px;
        }
        .form-control {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(123, 197, 20);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
          }
        }
        button {
          width: 11.25rem;
          height: 3rem;
          margin: 1.25rem auto 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          border-radius: 6.25rem;
          border: none;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
        }
      }
      .metamask-disconfirm {
        width: 100%;
        p {
          color: rgba(153, 164, 176, 0.8);
          margin-bottom: 5px;
          margin-top: 20px;
        }
        .form-control {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(123, 197, 20);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
          }
        }
        button {
          display: block;
          width: 11.25rem;
          height: 3rem;
          margin: 1.25rem auto 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          border-radius: 6.25rem;
          border: none;
          margin-bottom: 30px;
        }
      }
    }
  }
  #modal-info-updateInfo {
    .has-close {
      font-size: 1rem;
      height: 2rem;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      font-weight: bold;
      color: #fff;
    }
    .close-icon {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 11;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 3.75rem;
      height: 3.75rem;
      border: none;
      background: none;
      color: rgba(153, 164, 176, 0.8);
    }
    .userInfo {
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: center;
      .avatar-box {
        .avatar {
          width: 70px;
          height: 70px;
          border: 2px solid rgb(70, 70, 70);
          border-radius: 50%;
          cursor: pointer;
          vertical-align: middle;
        }
      }
      .name-box {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        margin-top: 0.625rem;
        color: rgb(245, 246, 247);
        font-weight: 600;
        .user-name {
          font-size: 1.25rem;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
        }
        .id {
          font-size: 1.25rem;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          word-break: break-all;
          text-align: center;
        }
      }
      .button-box {
        display: flex;
        justify-content: space-between;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        .button {
          display: block;
          width: 48%;
          height: 3rem;
          margin: 1.25rem 10px 0.5rem 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          border-radius: 6.25rem;
          border: none;
          font-size: 14px;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
        .button2 {
          display: block;
          width: 48%;
          height: 3rem;
          margin: 1.25rem 0px 0.5rem 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #581ac4;
          background-image: conic-gradient(from 1turn, #581ac4, #773cfd);
          border-radius: 6.25rem;
          font-weight: bold;
          cursor: pointer;
          border: none;
          font-size: 14px;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
        .button3 {
          display: block;
          width: 48%;
          height: 3rem;
          margin: 0rem 10px 1rem 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #581ac4;
          background-image: conic-gradient(from 1turn, #c43c1a, #fd3c3c);
          border-radius: 6.25rem;
          font-weight: bold;
          cursor: pointer;
          border: none;
          font-size: 14px;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
        .button4 {
          display: block;
          width: 48%;
          height: 3rem;
          margin: 0rem 0px 1rem 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #581ac4;
          background-image: conic-gradient(from 1turn, #c48e1a, #fda33c);
          border-radius: 6.25rem;
          font-weight: bold;
          cursor: pointer;
          border: none;
          font-size: 14px;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
      .changePass {
        width: 100%;
        p {
          color: rgba(153, 164, 176, 0.8);
          margin-bottom: 5px;
          margin-top: 20px;
        }
        .form-control {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(123, 197, 20);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
            color: rgb(123, 197, 20);
          }
        }
        button {
          display: block;
          width: 11.25rem;
          height: 3rem;
          margin: 1.25rem auto 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          border-radius: 6.25rem;
          border: none;
          margin-bottom: 30px;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
      .authentication {
        border-radius: 20px;
        background-color: rgb(30, 32, 36);
        padding: 1.25rem 1.25rem 0px;
        padding-bottom: 20px;
        flex: 1 1 0%;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        touch-action: pan-y;
        color: rgba(153, 164, 176, 0.6);
        max-height: 500px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          background-color: transparent;
          border-radius: 5px;
        }
        .google-step-summary-top {
          padding: 1rem 1.25rem;
          border-radius: 0.625rem;
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(45, 48, 53, 0.5);
          font-size: 0.75rem;
          line-height: 1.25rem;
          a {
            text-decoration: underline;
            margin-left: 0.25rem;
            color: rgb(93, 160, 0);
          }
          &.two {
            background-color: transparent;
            border: none;
            padding: 0px 1.25rem;
            margin-top: 0.75rem;
          }
        }
        .qrcode-warp {
          margin-top: 0.75rem;
          background: rgb(245, 246, 247);
          width: 8.625rem;
          height: 8.625rem;
          margin: 1.25rem auto 0px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          border-radius: 0.75rem;
          font-size: 0px;
          padding: 0.3125rem;
          img {
            width: 8rem;
            height: 8rem;
          }
        }
        .copy-input {
          margin-top: 0.75rem;
          .input {
            margin-top: 1.5rem;
            .input-label {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              line-height: 1em;
              height: 1.25rem;
              margin: 0px 1.125rem 0.375rem;
              color: rgba(153, 164, 176, 0.6);
            }
            .input-control {
              height: 3.5rem;
              position: relative;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              border: 1px solid rgb(45, 48, 53);
              background-color: rgba(45, 48, 53, 0.5);
              opacity: 1;
              border-radius: 1.5rem;
              padding: 0px 1.375rem;
              input {
                flex: 1 1 0%;
                width: 100%;
                height: 100%;
                min-width: 1rem;
                padding: 0px;
                border: none;
                background-color: transparent;
                color: rgb(245, 246, 247);
              }
              .copy-button {
                width: 2.5rem;
                height: 2.5rem;
                margin-top: 0.125rem;
                margin-right: -1.25rem;
                font-weight: bold;
                cursor: pointer;
                border-radius: 6.25rem;
                color: rgba(153, 164, 176, 0.8);
                border: none;
                background: none;
              }
            }
          }
        }
        .twofa-alert {
          margin: 1.125rem 0px 0px;
          padding: 0px 1.25rem 2.25rem;
          border-bottom: 1px solid rgba(176, 179, 191, 0.1);
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
        .codes {
          margin-top: 1.5rem;
          p {
            margin: 0px;
            height: 1.25rem;
            line-height: 1.25rem;
            text-align: center;
          }
          .form-control {
            margin-top: 20px;
            border: 1px solid rgb(45, 48, 53);
            background-color: rgba(45, 48, 53, 0.5);
            border-left: none;
            border-radius: 15px;
            color: rgb(123, 197, 20);
            &:focus {
              background: none;
              border: 1px solid rgb(123, 197, 20);
              outline: none;
              box-shadow: none;
            }
          }
        }
        button {
          display: block;
          width: 11.25rem;
          height: 3rem;
          margin: 1.25rem auto 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          border-radius: 6.25rem;
          border: none;
          .button-inner {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
      .metamask-confirm {
        width: 100%;
        p {
          color: rgba(153, 164, 176, 0.8);
          margin-bottom: 5px;
          margin-top: 20px;
        }
        .form-control {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(123, 197, 20);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
          }
        }
        button {
          width: 11.25rem;
          height: 3rem;
          margin: 1.25rem auto 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          border-radius: 6.25rem;
          border: none;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
        }
      }
      .metamask-disconfirm {
        width: 100%;
        p {
          color: rgba(153, 164, 176, 0.8);
          margin-bottom: 5px;
          margin-top: 20px;
        }
        .form-control {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(123, 197, 20);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
          }
        }
        button {
          display: block;
          width: 11.25rem;
          height: 3rem;
          margin: 1.25rem auto 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(93, 160, 0);
          background-image: conic-gradient(
            from 1turn,
            rgb(69, 136, 2),
            rgb(123, 197, 20)
          );
          font-weight: bold;
          cursor: pointer;
          border-radius: 6.25rem;
          border: none;
          margin-bottom: 30px;
        }
      }
    }
  }
</style>
