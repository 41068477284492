import axios from 'axios';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import store from '..';
Vue.use(VueCookies);
const token = window.$cookies.get('isSession');
if (token != null && token.key != null) {
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common.Authorization = `Bearer ${token.key}`;
}
function checkCodeStatus(code) {
  if (code >= 500) {
    return 500;
  }
  if (code >= 400) {
    return 400;
  }
  return false;
}
const instance = axios.create({
  // baseURL: process.env.VUE_APP_API_URL_DNN,
  baseURL: process.env.VUE_APP_API_URL,
  ContentType: 'application/json',
  Accept: 'application/json',
});

instance.interceptors.request.use(
  (config) => {
    const newConfig = config;
    const lang = window.$cookies.get("language");
    if (!lang) {
      config.headers["accept-language"] = "en";
    } else if (lang == "vi") {
      config.headers["accept-language"] = "vn";
    } else if (lang == "cn") {
      config.headers["accept-language"] = "zh";
    } else {
      config.headers["accept-language"] = lang;
    }
    store.commit('onLoad');
    return newConfig;
  },
  (error) => Promise.reject(error)
);
instance.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  (response) => {
    store.commit('outLoad');
    if (response.data.status) {
      if (
        response.data.message &&
        response.data.message !== null &&
        response.data.message !== '' &&
        response.data.message.length > 0
      ) {
        store.commit('core/SUCCESS_MESSANGE', response.data.message, {
          root: true,
        });
      }
      return response.data;
    }
    store.commit('core/ERROR_MESSANGE', response.data.message, { root: true });
  },
  (error) => {
    store.commit('outLoad');
    if (error.response) {
      if (error.response.status === 401) {
        store.commit('auth/LOGOUT_SUCCESS');
        window.location.href = '/';
        return;
      }
      if (checkCodeStatus(error.response.status) === 500) {
        store.commit('core/ERROR_500', 500, { root: true });
        return;
      }

      if (checkCodeStatus(error.response.status) === 400) {
        store.commit(
          'core/ERROR_MESSAGE',
          'Something went wrong. Please try again',
          {
            root: true,
          }
        );
      }
      // eslint-disable-next-line default-case
      //   switch (error.response.status) {
      //     case 500:
      //     	router.replace('/500');
      //     	break;
      //     case 401:
      //       store.commit('auth/LOGOUT_SUCCESS');
      //       window.location.href = '/';
      //       break;
      //   }
    }
  }
);

export default instance;
