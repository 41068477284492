<template>
  <div class="gameIframe">
    <div class="gameIframe-back" @click="handlechangeShowIframe">
      <img src="~@/assets/images/icon/back.png" alt="" />
    </div>
    <iframe :src="src" frameborder="0"></iframe>
  </div>
</template>

<script>
  export default {
    props: ['showIframe', 'src'],
    data() {
      return {
        iframe:{
          src: '',
          status: false,
        }
      }
    },
    methods: {
      handlechangeShowIframe() {
        this.$store.commit('getGame', this.iframe);
      },
    },
  };
</script>

<style lang="scss">
  .gameIframe {
    width: 100%;
    min-height: calc(100vh - 68px);
    height: 100%;
    position: relative;
    z-index: 3;
    background-color: #1e2024;
    .gameIframe-back {
      cursor: pointer;
      margin-bottom: 10px;
      img {
        width: 30px;
        height: 30px;
        &:hover {
          filter: brightness(150%);
        }
      }
      &:hover {
        color: #fff;
      }
    }
    iframe {
      width: 100%;
      height: 100%;
      min-height: calc(100vh - 102px);
    }
  }
</style>
