const axios = require('../config/axios.config.js').default;
export default {
  namespaced: true,
  state: () => ({
    error: '',
    success: '',
    notificationImage: {},
  }),

  getters: {
    Error: (state) => state.error,
    Success: (state) => state.success,
    NotificationImage: (state) => {
      return state.notificationImage;
    },
  },

  actions: {
    req_getNotificationImages: async function({ commit }) {
      axios.get('notification?type=2').then(function(data) {
        if (data) {
          commit('SET_NOTIFICATION_IMAGE', data.data);
        }
      });
    },
  },

  mutations: {
    SUCCESS_MESSANGE(state, messange) {
      state.success = messange;
    },
    ERROR_MESSANGE(state, messange) {
      state.error = messange;
    },
    ERROR_500(){},
    SET_NOTIFICATION_IMAGE: function(state, data) {
      state.notificationImage = data;
    },
  },
};
