const axios = require('../config/axios.config.js').default;

export default {
  namespaced: true,
  state: () => ({
    listSubject: {},
    listTicket: {},
    detail_ticket: {},
  }),

  getters: {
    ListSubject: (state) => state.listSubject,
    ListTicket: (state) => state.listTicket,
    DetailTicket: (state) => state.detail_ticket,
  },

  actions: {
    req_getTicket({ commit }) {
      axios.get(`ticket`).then((response) => {
        if (response) {
          commit('GET_TICKET_SUCCESS', response);
        }
      });
    },
    req_postSubject({ commit, dispatch }, input) {
      axios.post(`ticket/post-ticket`, input).then((response) => {
        if (response) {
          commit('SEND_TICKET_SUCCESS', response);
          dispatch('req_getTicket');
        }
      });
    },
    req_getTicketDetail({ commit }, input) {
      axios.get(`ticket/get-ticket-detail/` + input).then((response) => {
        if (response) {
          commit('SET_DETAIL_TICKET', response);
        }
      });
    },
    req_postTicketMessage({ commit }, input) {
      axios.post(`ticket/post-ticket`, input).then((response) => {
        if (response) {
          commit('SEND_MESSANGE_SUCCESS');
        }
      });
    },
  },
  mutations: {
    GET_TICKET_SUCCESS(state, data) {
      state.listTicket = data.data.list_ticket;
      state.listSubject = data.data.subject_ticket;
    },
    SEND_TICKET_SUCCESS() {},
    SET_DETAIL_TICKET: function(state, data) {
      state.detail_ticket = data.data.list_detail;
    },
    SEND_MESSANGE_SUCCESS() {},
  },
};
