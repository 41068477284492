<template>
  <b-modal id="modal-listCoin" hide-footer hide-header>
    <button class="close-icon" @click="$bvModal.hide('modal-listCoin')">
      <i class="fas fa-times"></i>
    </button>
    <div class="listCoin-content">
      <!-- <b-input-group class="mt-3">
        <template #prepend
          ><b-input-group-text
            ><i class="fas fa-search"></i> </b-input-group-text
        ></template>
        <b-form-input></b-form-input>
      </b-input-group> -->
      <div class="listCoin-list">
        <template v-for="(value, name, index) in Balance">
          <div
            class="listCoin-item"
            :class="name === activeName ? 'activeCoin' : ''"
            :key="index"
            @click="chooseActiveCoin(name)"
            v-if="checkEBP(name)"
          >
            <div class="listCoin-item-flex">
              <!-- <div class="coin-wrap">
              <img class="coin-icon" src="~@/assets/images/home/coin.png" />
            </div> -->
              <div class="name-wrap">
                <div class="currency-name">{{ name }}</div>
              </div>
              <div class="amount-wrap">
                <div class="coin notranslate monospace">
                  <div class="amount">
                    <span class="amount-str">{{ value }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    props: ['activeName'],
    data() {
      return {
        active: 0,
      };
    },
    computed: {
      ...mapGetters({
        Balance: 'dashboard/Balance',
        ListCoin: 'wallet/ListCoin',
      }),
    },
    methods: {
      chooseActiveCoin(index) {
        this.$emit('chooseCoin', index);
      },
      checkEBP(name) {
        if (name === 'EBP') {
          for (var coin in this.ListCoin) {
            if (coin === name) {
              return true;
            }
          }
          return false;
        }
        return true;
      },
    },
  };
</script>

<style lang="scss">
  .modal.show .modal-dialog {
    width: 100%;
    height: 100%;
  }
  #modal-listCoin {
    &.modal {
      display: flex !important;
      justify-content: center;
      align-items: flex-start;
      padding-left: 0px !important;
      .modal-content {
        height: auto;
        border-radius: 1.25rem;
        position: relative;
        background-color: rgb(23, 24, 27);
        @media (max-width: 480px) {
          font-size: 14px;
          padding: 0rem;
        }
      }
    }
  }
  .listCoin-content {
    .input-group-prepend {
      border-right: none;
      .input-group-text {
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(45, 48, 53, 0.5);
        border-right: none;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }
    .form-control {
      border: 1px solid rgb(45, 48, 53);
      background-color: rgba(45, 48, 53, 0.5);
      border-left: none;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      &:focus {
        background: none;
        border: 1px solid rgb(123, 197, 20);
        outline: none;
        box-shadow: none;
      }
    }
  }
</style>
<style lang="scss" scoped>
  #modal-listCoin {
    .close-icon {
      position: absolute;
      right: -10px;
      top: -15px;
      z-index: 11;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 3.75rem;
      height: 3.75rem;
      border: none;
      background: none;
      color: rgba(153, 164, 176, 0.8);
    }
    .listCoin-content {
      .listCoin-list {
        box-sizing: border-box;
        height: 100%;
        max-height: 550px;
        overflow-y: auto;
        touch-action: pan-y;
        margin-top: 10px;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          background-color: transparent;
          border-radius: 5px;
        }
        .listCoin-item {
          padding: 0.4375rem 0.625rem;
          font-size: 0.875rem;
          border-radius: 1.25rem;
          margin: 0.25rem 0px;
          border: 1px solid transparent;
          min-height: 2.0625rem;
          &.activeCoin {
            border: 1px solid rgb(123, 197, 20);
            border-radius: 20px;
          }
          &:hover {
            background-color: rgba(106, 108, 109, 0.39);
            cursor: pointer;
          }
          .listCoin-item-flex {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            .coin-wrap {
              position: relative;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              .coin-icon {
                width: 1.875rem;
                height: 1.875rem;
                margin-right: 0.9375rem;
                box-shadow: rgb(0 0 0 / 10%) 0px 5px 8px;
                border-radius: 0.9375rem;
              }
            }
            .name-wrap {
              .currency-name {
                color: rgb(255, 255, 255);
                font-weight: 800;
                height: 1.25rem;
                line-height: 1.25rem;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: start;
                justify-content: flex-start;
              }
            }
            .amount-wrap {
              flex-direction: column;
              align-items: flex-end;
              display: flex;
              -webkit-box-align: center;
              margin-left: auto;
              .coin {
                text-align: right;
                color: rgb(255, 255, 255);
                font-weight: 800;
                height: 1.375rem;
                line-height: 1.375rem;
                display: inline-flex;
                vertical-align: middle;
                -webkit-box-align: center;
                align-items: center;
                white-space: nowrap;
                .amount {
                  .amount-str {
                    width: 8em;
                    display: inline-block;
                    .suffix {
                      opacity: 0.5;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
