<template>
  <div class="client-container" :class="isMode === 1 ? '' : 'bg-light'">
    <LeftSideBar
      :showMenu="showMenu"
      :showDocument="showDocument"
      :showReports="showReports"
      :showPromotions="showPromotions"
      @showMenu="changeStatusMenu"
      :isMode="isMode"
      @changeMode="changeModeParent"
      @changeShowHideDocAndReport="changeShowHideDocAndReportParent"
    />
    <Header
      :showChat="showChat"
      @clickedShowChat="ChaneShowChat"
      :showMenu="showMenu"
      @clickChangeShowHideMenu="changeStatusMenu"
      @hideDocumentAndReport="hideDocumentAndReportParent"
    />
    <loader />
    <!-- showChat && widthBody > 991 ? 'mr-cuttom' : '', -->
    <div
      class="client-content "
      :style="`padding-top:${height + 20}px;`"
      :class="[widthBody > 991 ? (showMenu ? 'ml-custom2' : 'ml-custom') : '']"
    >
      <transition-group
        tag="div"
        name="custom-classes-transition"
        enter-active-class="animate__animated  animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <router-view v-if="!ShowGame" key="main-content"></router-view>
        <IframeGame
          v-else
          :showIframe="ShowGame"
          :src="UrlGame"
          key="game-iframe"
        />
        <!-- <FireWork
          key="bg"
          :box-height="'100%'"
          :box-width="'100%'"
          v-if="showFirework"
          @close="showFirework = false"
        /> -->
      </transition-group>
      <Footer />
    </div>
    <!-- <ChatBox :showChat="showChat" @clickedHideChat="ChaneShowChat" /> -->
  </div>
</template>

<script>
  // import { snow, Snow } from '../plugins/snow';
  import { mapGetters } from 'vuex';

  import LeftSideBar from '@/components/layout-sub/LeftSideBar.vue';
  // import ChatBox from '@/components/layout-sub/ChatBox.vue';
  import Header from '@/components/layout-sub/Header.vue';
  import Footer from '@/components/layout-sub/Footer.vue';
  import loader from '@/components/Loader.vue';
  import IframeGame from '@/components/game/Iframe.vue';
  // import FireWork from '@/components/Background/Firework/Fireworks2.vue';

  export default {
    components: {
      LeftSideBar,
      // FireWork,
      Header,
      Footer,
      loader,
      IframeGame,
    },
    data() {
      return {
        showChat: true,
        showMenu: true,
        widthBody: null,
        isMode: 1,
        height: 60,
        showDocument: false,
        showReports: false,
        showPromotions: false,
        iframe: {
          src: '',
          status: false,
        },
        showFirework: true,
      };
    },
    computed: {
      ...mapGetters({
        Game: 'Game',
      }),
      ShowGame() {
        return this.Game.show;
      },
      UrlGame() {
        return this.Game.url;
      },
    },
    methods: {
      ChaneShowChat(a) {
        this.showChat = a;
      },
      changeStatusMenu(status) {
        this.showMenu = status;
      },
      changeModeParent(mode) {
        this.isMode = mode;
      },
      hideDocumentAndReportParent(status) {
        this.showDocument = status;
        this.showReports = status;
      },
      changeShowHideDocAndReportParent(report, doc, promotion) {
        this.showDocument = doc;
        this.showReports = report;
        this.showPromotions = promotion;
      },
      handleResize() {
        this.widthBody = window.innerWidth;
        if (window.innerWidth > 1439) {
          this.showChat = true;
          this.showMenu = true;
        } else {
          this.showChat = false;
          this.showMenu = false;
        }
      },
      async getH() {
        const header = document.querySelector('#header');
        this.height = header.clientHeight;
      },
    },

    async mounted() {
      await this.getH();
    },
    created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    watch: {
      $route(to, from) {
        if (to.name !== from.name) {
          this.iframe.src = '';
          this.iframe.status = false;
          this.$store.commit('getGame', this.iframe);
          if (this.widthBody <= 480) {
            this.showMenu = false;
          }
        }
      },
    },

    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
  };
</script>

<style scoped lang="scss">
  .client-container {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    // background-color: #24262b;
    display: flex;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
    background-image: url('~@/assets/images/bg2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    .client-content {
      width: 100%;
      height: 100%;
      // z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100vh;
      background: url('~@/assets/images/bg7.jpg');
      box-shadow: inset 0 0 0 2000px rgb(27, 29, 33, 0.5);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      @media (max-width: 991px) {
        padding: 10px 0px;
      }
      @media (max-width: 575px) {
        background-repeat: repeat;
        background-size: contain;
      }
    }
  }
  .ml-custom {
    padding-left: 76px !important;
    @media (max-width: 575px) {
      padding-left: 0px !important;
    }
  }
  .ml-custom2 {
    padding-left: 240px !important;
  }
  .mr-cuttom {
    padding-right: 360px;
  }
  .bg-light {
    background-color: #f7f7fa !important;
  }
</style>
