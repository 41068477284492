const axios = require('../config/axios.config.js').default;

export default {
  namespaced: true,
  state: () => ({
    infoTopLeader: {},
    listTopLeader: {},
    infoTrader: {},
    listTrader: {},
  }),
  getters: {
    InfoTopLeader: (state) => state.infoTopLeader,
    ListTopLeader: (state) => state.listTopLeader,
    InfoTrader: (state) => state.infoTrader,
    ListTrader: (state) => state.listTrader,
  },
  actions: {
    req_getInfoTopLeader({ commit }) {
      axios.get(`top-leader/get-info`).then((response) => {
        commit('SET_INFO_TOP_LEADER', response.data);
      });
    },
    req_getListTopLeader({ commit }) {
      axios.get(`top-leader/get-list`).then((response) => {
        commit('SET_LIST_TOP_LEADER', response.data);
      });
    },
    req_getInfoTopTrader({ commit }) {
      axios.get(`top-trade/get-info`).then((response) => {
        commit('SET_INFO_TRADER', response.data);
      });
    },
    req_getListTopTrader({ commit }) {
      axios.get(`top-trade/get-list`).then((response) => {
        commit('SET_LIST_TRADER', response.data);
      });
    },
  },
  mutations: {
    SET_INFO_TOP_LEADER(state, data) {
      state.infoTopLeader = data.infoTopLeader;
    },
    SET_LIST_TOP_LEADER(state, data) {
      state.listTopLeader = data.listTopLeader;
    },
    SET_INFO_TRADER(state, data) {
      state.infoTrader = data.infoTopTrader;
    },
    SET_LIST_TRADER(state, data) {
      state.listTrader = data.listTopTrader;
    },
  },
};
