<template>
  <div>
    <b-modal :id="`modal-authen-${type}`" hide-footer hide-header modal-class="modal-authen">
      <div class="has-close">
        <div @click="clickHideAuthen"><i class="fas fa-chevron-left"></i></div>
        {{ $t('GgAuthen.23') }}
      </div>
      <button class="close-icon" @click="$bvModal.hide(`modal-authen-${type}`)">
        <i class="fas fa-times"></i>
      </button>
      <div class="modal-authen-content">
        <div class="google-step-summary-top">
          {{ $t('GgAuthen.24') }}
          <a
            target="_blank"
            href="https://support.google.com/accounts/answer/1066447?hl=en&amp;rd=1"
            >{{ $t('GgAuthen.25') }}</a
          >. {{ $t('GgAuthen.26') }}..
        </div>
        <div class="google-step-summary-top two">{{ $t('GgAuthen.27') }}.</div>
        <div class="qrcode-warp">
          <img :src="Auth.qr" alt="qrcode" />
        </div>
        <div class="copy-input">
          <div class="input">
            <div class="input-label">{{ $t('GgAuthen.28') }}</div>
            <div class="input-control">
              <input
                type="text"
                readonly=""
                value=""
                v-model="Auth.secret"
              /><button class="button button-normal copy-button">
                <div
                  class="button-inner"
                  v-clipboard:copy="Auth.secret"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <i class="fas fa-copy"></i>
                </div>
              </button>
            </div>
          </div>
        </div>
        <p class="twofa-alert">{{ $t('GgAuthen.30') }}.</p>
        <form @submit.prevent="onConfirmAuth">
          <div class="codes">
            <p>{{ $t('GgAuthen.31') }}</p>
            <b-form-input
              type="text"
              placeholder="Verification code"
              v-model="authCode"
              required
            ></b-form-input>
          </div>
          <button class="button button-normal" type="submit">
            <div class="button-inner">
              {{ Auth.enable ? $t('GgAuthen.32') : $t('GgAuthen.33') }}
            </div>
          </button>
        </form>
      </div>
    </b-modal>
    <modalCopy :content="content" type="authen" />
  </div>
</template>

<script>
import modalCopy from './PopupCopy.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  props: ['type'],
  components: { modalCopy },
  data() {
    return {
      authCode: '',
      content: '',
    };
  },
  computed: {
    ...mapState(['isLogin']),
    ...mapGetters({
      Auth: 'auth/Auth',
    }),
  },
  methods: {
    ...mapMutations(['onLoad', 'outLoad']),
    clickHideAuthen() {
      this.$emit('clickHideAuthen');
    },
    async onConfirmAuth() {
      this.onLoad();
      const token = await this.genarateCaptChaV3('authentication');
      if (!token) {
        this.$toastr.e(this.$t('Message.242'), this.$t('Message.243'));
        return;
      }
      if (this.authCode !== '') {
        this.$store.dispatch('auth/req_changeAuth', {
          authCode: this.authCode,
          secret: this.Auth.secret,
          token: token,
        });
        this.$store.dispatch('auth/req_getAuth');
        this.action = 0;
      }
      this.$store.dispatch('auth/req_getAuth');
    },
    onCopy(e) {
      this.content = e.text;
      this.$bvModal.show('modal-copy-authen');
      this.$bvModal.hide(`modal-authen-${this.type}`);
    },
    onError() {
      alert('Failed to copy texts');
    },
  },
  created() {
    if (this.isLogin) {
      // this.$store.dispatch('auth/req_getAuth');
    }
  },
};
</script>

<style lang="scss">
.modal.show .modal-dialog {
  width: 100%;
  height: 100%;
}
.modal-authen {
  &.modal {
    display: flex !important;
    justify-content: center;
    align-items: flex-start;
    padding-left: 0px !important;
    &::-webkit-scrollbar {
      width: 0px !important;
    }
    .modal-content {
      height: auto;
      border-radius: 1.25rem;
      position: relative;
      background-color: rgb(23, 24, 27);
      @media (max-width: 480px) {
        font-size: 14px;
        padding: 0rem;
      }
    }
  }
}
.listCoin-content {
  .input-group-prepend {
    border-right: none;
    .input-group-text {
      border: 1px solid rgb(45, 48, 53);
      background-color: rgba(45, 48, 53, 0.5);
      border-right: none;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }
  .form-control {
    border: 1px solid rgb(45, 48, 53);
    background-color: rgba(45, 48, 53, 0.5);
    border-left: none;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    &:focus {
      background: none;
      border: 1px solid rgb(123, 197, 20);
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
<style lang="scss" scoped>
.modal-authen {
  .has-close {
    font-size: 1rem;
    height: 2rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
    svg {
      color: #fff;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .close-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 11;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 3.75rem;
    height: 3.75rem;
    border: none;
    background: none;
    color: rgba(153, 164, 176, 0.8);
  }
  .modal-authen-content {
    border-radius: 20px;
    background-color: rgb(30, 32, 36);
    padding: 1.25rem 1.25rem 0px;
    flex: 1 1 0%;
    height: 100%;
    touch-action: pan-y;
    color: rgba(153, 164, 176, 0.6);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: transparent;
      border-radius: 5px;
    }
    .google-step-summary-top {
      padding: 1rem 1.25rem;
      border-radius: 0.625rem;
      border: 1px solid rgb(45, 48, 53);
      background-color: rgba(45, 48, 53, 0.5);
      font-size: 0.75rem;
      line-height: 1.25rem;
      a {
        text-decoration: underline;
        margin-left: 0.25rem;
        color: rgb(93, 160, 0);
      }
      &.two {
        background-color: transparent;
        border: none;
        padding: 0px 1.25rem;
        margin-top: 0.75rem;
      }
    }
    .qrcode-warp {
      margin-top: 0.75rem;
      background: rgb(245, 246, 247);
      width: 8.625rem;
      height: 8.625rem;
      margin: 1.25rem auto 0px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      border-radius: 0.75rem;
      font-size: 0px;
      padding: 0.3125rem;
      img {
        width: 8rem;
        height: 8rem;
      }
    }
    .copy-input {
      margin-top: 0.75rem;
      .input {
        margin-top: 1.5rem;
        .input-label {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          line-height: 1em;
          height: 1.25rem;
          margin: 0px 1.125rem 0.375rem;
          color: rgba(153, 164, 176, 0.6);
        }
        .input-control {
          height: 3.5rem;
          position: relative;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(45, 48, 53, 0.5);
          opacity: 1;
          border-radius: 1.5rem;
          padding: 0px 1.375rem;
          input {
            flex: 1 1 0%;
            width: 100%;
            height: 100%;
            min-width: 1rem;
            padding: 0px;
            border: none;
            background-color: transparent;
            color: rgb(245, 246, 247);
          }
          .copy-button {
            width: 2.5rem;
            height: 2.5rem;
            margin-top: 0.125rem;
            margin-right: -1.25rem;
            font-weight: bold;
            cursor: pointer;
            border-radius: 6.25rem;
            color: rgba(153, 164, 176, 0.8);
            border: none;
            background: none;
          }
        }
      }
    }
    .twofa-alert {
      margin: 1.125rem 0px 0px;
      padding: 0px 1.25rem 2.25rem;
      border-bottom: 1px solid rgba(176, 179, 191, 0.1);
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
    .codes {
      margin-top: 1.5rem;
      p {
        margin: 0px;
        height: 1.25rem;
        line-height: 1.25rem;
        text-align: center;
      }
      .form-control {
        margin-top: 20px;
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(45, 48, 53, 0.5);
        border-left: none;
        border-radius: 15px;
        color: rgb(123, 197, 20);
        &:focus {
          background: none;
          border: 1px solid rgb(123, 197, 20);
          outline: none;
          box-shadow: none;
        }
      }
    }
    button {
      display: block;
      width: 11.25rem;
      height: 3rem;
      margin: 1.25rem auto 10px;
      color: rgb(245, 246, 247);
      box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
      background-color: rgb(93, 160, 0);
      background-image: conic-gradient(
        from 1turn,
        rgb(69, 136, 2),
        rgb(123, 197, 20)
      );
      font-weight: bold;
      cursor: pointer;
      border-radius: 6.25rem;
      border: none;
      .button-inner {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
