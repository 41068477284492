<template>
  <div>
    <b-modal
      :id="`modal-transaction-${type}`"
      hide-footer
      hide-header
      modal-class="modal-transaction"
    >
      <div class="has-close">
        <div class="title">{{ $t('header.3') }}</div>
        <div class="content">
          <button>
            <i class="fas fa-wallet"></i><span>{{ $t('LeftSideBar.15') }}</span>
          </button>
        </div>
      </div>
      <button
        class="close-icon"
        @click="$bvModal.hide(`modal-transaction-${type}`)"
      >
        <i class="fas fa-times"></i>
      </button>
      <div class="transaction-content">
        <div class="transaction-content-action">
          <div
            class="action-item"
            :class="action === 1 ? 'action-active' : ''"
            @click="changeAction(1)"
          >
            <img src="~@/assets/images/icon/deposit.svg" alt="" />
            <div class="action-title">{{ $t('Transaction.55') }}</div>
          </div>
          <div
            class="action-item"
            :class="action === 2 ? 'action-active' : ''"
            @click="
              changeAction(2);
              activeCoin = 0;
            "
          >
            <img src="~@/assets/images/icon/withdraw.svg" alt="" />
            <div class="action-title">{{ $t('Transaction.56') }}</div>
          </div>
          <div
            class="action-item"
            :class="action === 3 ? 'action-active' : ''"
            @click="
              changeAction(3);
              activeCoin = 0;
            "
          >
            <img src="~@/assets/images/icon/transfer.svg" alt="" />
            <div class="action-title">{{ $t('Transaction.57') }}</div>
          </div>
          <!-- Swap -->
          <!-- <div
            class="action-item"
            :class="action === 4 ? 'action-active' : ''"
            @click="
              action = 4;
              activeCoin = 0;
            "
          >
            <img src="~@/assets/images/icon/swap.svg" alt="" />
            <div class="action-title">Swap</div>
          </div> -->
          <div
            class="action-item"
            :class="action === 5 ? 'action-active' : ''"
            @click="
              changeAction(5);
              activeCoin = 0;
            "
          >
            <img src="~@/assets/images/icon/game.svg" alt="" />
            <div class="action-title">{{ $t('Transaction.58') }}</div>
          </div>
          <div
            class="action-item"
            :class="action === 6 ? 'action-active' : ''"
            @click="
              changeAction(6);
              activeCoin = 0;
            "
          >
            <img src="~@/assets/images/icon/transfer.svg" alt="" />
            <div class="action-title">{{ $t('Transaction.59') }}</div>
          </div>
        </div>
        <!-- Deposit -->
        <template v-if="action === 1">
          <div class="action-withdraw">
            <div class="action-withdraw-header">
              <div class="box">
                <div class="type">
                  <template v-for="(coin, name, i) in listCoins">
                    <button
                      :key="'coin-deposit' + i"
                      :class="activeCoin === i + 1 ? 'active' : ''"
                      v-if="coin.Deposit == true"
                      @click="
                        activeCoin = i + 1;
                        getDeposit(coin.ID);
                      "
                    >
                      {{ coin.Name }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <div
              class="action-deposit"
              v-for="(coin, name, i) in listCoins"
              :key="'deposit-content' + i"
            >
              <template v-if="activeCoin === i + 1 && coin.Deposit == true">
                <div class="action-deposit-header">
                  <div class="label">
                    <div>
                      {{ $t('Transaction.60') }}
                      <span class="cl-primary"></span>
                    </div>
                  </div>
                  <div class="box">
                    <div class="type"></div>
                    <div class="cont">
                      <div class="address">
                        <input
                          :key="CoinDeposit.address"
                          type="text"
                          v-model="CoinDeposit.address"
                          readonly
                        />
                      </div>
                      <button
                        class="button button-normal copy-button"
                        v-clipboard:copy="CoinDeposit.address"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        type="button"
                      >
                        <div class="button-inner">
                          <i class="fas fa-copy"></i>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="label mt-3">
                    <div>
                      {{ $t('Transaction.75') }}
                      <span class="cl-primary"></span>
                    </div>
                  </div>
                  <div class="box">
                    <div class="type"></div>
                    <div class="cont">
                      <div class="address">
                        <input type="text" v-model="coin.rate" readonly />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="action-deposit-qrcode">
                  <img :src="CoinDeposit.Qr" alt="" />
                </div>
                <div class="action-notification">
                  <p>
                    <span class="cl-primary">{{ $t('Transaction.61') }}:</span>
                    {{ $t('Transaction.62') }}
                    <span class="cl-primary">{{ coin.Name }}</span>
                    {{ $t('Transaction.63') }}
                  </p>
                  <p>
                    <span class="cl-primary">{{ $t('Transaction.64') }}:</span>
                    {{ $t('Transaction.65') }}
                  </p>
                  <p>
                    <span class="cl-primary">{{ $t('Transaction.61') }}:</span>
                    {{ $t('Transaction.66') }}
                  </p>
                </div>
              </template>
            </div>
          </div>
          <div class="transaction-bottom" v-if="false">
            <div class="transaction-bottom-flex">
              <div class="cont">{{ $t('Transaction.67') }}.</div>
              <button
                class="button button-normal"
                @click="handleClickShowAuthen"
              >
                <div class="button-inner">{{ $t('Transaction.68') }}</div>
              </button>
            </div>
          </div>
          <div class="transaction-bottom" v-if="false">
            <div class="title">{{ $t('Transaction.69') }}</div>
            <div class="support-wrap">
              <div class="support-item">
                <img
                  src="https://static.bc.game/static/media/metamask.466e8f72.png"
                  alt=""
                />{{ $t('Transaction.70') }}
              </div>
            </div>
          </div>
        </template>
        <!-- Withdraw -->
        <template v-if="action === 2">
          <div class="action-withdraw">
            <div class="action-withdraw-header">
              <div class="box">
                <div class="type">
                  <template v-for="(coin, name, i) in listCoins">
                    <button
                      :key="'coin-withdraw' + i"
                      :class="activeCoin === i + 1 ? 'active' : ''"
                      v-if="coin.Withdraw.length > 0"
                      @click="
                        activeCoin = i + 1;
                        formWithdraw.ecosystem = '';
                        fee = '';
                        formWithdraw.amount = '';
                        amountFee = '';
                      "
                    >
                      {{ coin.Name }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <template v-for="(coin, name, i) in listCoins">
              <div
                class="action-withdraw-qrcode"
                v-if="activeCoin === i + 1 && coin.Withdraw.length > 0"
                :key="'withdraw' + i"
              >
                <b-form @submit.prevent="onWithdraw(coin.ID)">
                  <!-- Coin to -->
                  <p>{{ $t('Transaction.71') }}</p>
                  <b-form-input :value="coin.Name" readonly></b-form-input>
                  <!-- Hệ sinh thái -->
                  <p v-if="false">{{ $t('Transaction.72') }}</p>
                  <b-form-select
                    v-model="formWithdraw.ecosystem"
                    variant="dark"
                    @change="onChangeEcosystem($event, coin.Key)"
                    required
                    v-if="false"
                  >
                    <b-form-select-option value="" required>{{
                      $t('Transaction.73')
                    }}</b-form-select-option>
                    <b-form-select-option
                      :value="item.id"
                      v-for="item in ecosystems"
                      :key="'withdraw-option' + item.id"
                      >{{ item.name }}</b-form-select-option
                    >
                  </b-form-select>
                  <!-- Địa chỉ -->
                  <p>{{ $t('InforUser.37') }}</p>
                  <b-form-input
                    v-model="formWithdraw.address"
                    required
                  ></b-form-input>
                  <!-- Số lượng nhỏ nhất theo coin -->
                  <p>{{ $t('Transaction.74') }} {{ coin.Symbol }}</p>
                  <b-form-input
                    :value="
                      TruncateToDecimals(
                        listCoins[coin.Key].WithdrawMin / coin.rate + 0,
                        '',
                        8
                      )
                    "
                    readonly
                  ></b-form-input>
                  <!-- Số lượng nhỏ nhất theo $ -->
                  <p>{{ $t('Transaction.74') }} $</p>
                  <b-form-input
                    :value="TruncateToDecimals(listCoins[coin.Key].WithdrawMin)"
                    readonly
                  ></b-form-input>
                  <!-- Rate -->
                  <p>{{ $t('Transaction.75') }}</p>
                  <b-form-input :value="coin.rate" readonly></b-form-input>
                  <!-- Số lượng -->
                  <p>{{ $t('Transaction.76') }} $</p>
                  <b-form-input
                    v-model="formWithdraw.amount"
                    v-on:keyup="changeAmount($event, coin.Key)"
                    required
                  ></b-form-input>
                  <!-- Phí rút -->
                  <p>{{ $t('Transaction.77') }} ( {{ fee }}% )</p>
                  <b-form-input v-model="amountFee" readonly></b-form-input>
                  <!-- Google authen -->
                  <p>{{ $t('GgAuthen.25') }}</p>
                  <b-form-input
                    v-model="formWithdraw.auth"
                    required
                  ></b-form-input>
                  <!-- Btn - withdraw -->
                  <div class="custom-btn">
                    <button
                      type="submit"
                      class="button button-normal mt-3"
                      v-if="showBtnWithdraw"
                    >
                      <div class="button-inner">{{ $t('Transaction.56') }}</div>
                    </button>
                    <button
                      type="submit"
                      class="button button-normal mt-3 btn-disable"
                      v-else
                    >
                      <div class="button-inner">{{ $t('Transaction.56') }}</div>
                    </button>
                  </div>
                </b-form>
                <!-- Notification -->
                <div class="action-notification">
                  <p>
                    {{ $t('Transaction.78') }}
                  </p>
                </div>
              </div>
            </template>
          </div>

          <div class="transaction-bottom" v-if="false">
            <div class="transaction-bottom-flex">
              <div class="cont">{{ $t('Transaction.67') }}.</div>
              <button
                class="button button-normal"
                @click="handleClickShowAuthen"
              >
                <div class="button-inner">{{ $t('Transaction.68') }}</div>
              </button>
            </div>
          </div>
        </template>
        <!-- Transfer -->
        <template v-if="action === 3">
          <div class="action-withdraw">
            <div class="action-withdraw-header">
              <div class="box">
                <div class="type">
                  <template v-for="(coin, name, i) in listCoins">
                    <button
                      :key="'coin-transfer' + i"
                      :class="activeCoin === i + 1 ? 'active' : ''"
                      v-if="coin.Transfer == true"
                      @click="
                        activeCoin = i + 1;
                        formWithdraw.ecosystem = '';
                        fee = '';
                        formWithdraw.amount = '';
                        amountFee = '';
                      "
                    >
                      {{ coin.Name }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <template v-for="(coin, name, i) in listCoins">
              <div
                class="action-withdraw-qrcode"
                :key="'transfer' + i"
                v-if="activeCoin === i + 1 && coin.Transfer == true"
              >
                <b-form @submit.prevent="onTransfer(coin.ID)">
                  <!-- Coin to -->
                  <p>{{ $t('Transaction.71') }}</p>
                  <b-form-input :value="coin.Name" readonly></b-form-input>
                  <!-- User Receive -->
                  <p>{{ $t('Transaction.79') }}</p>
                  <b-form-input
                    v-model="formTransfer.email"
                    required
                  ></b-form-input>
                  <!-- Số lượng -->
                  <p>{{ $t('Transaction.76') }}</p>
                  <b-form-input
                    v-model="formTransfer.amount"
                    required
                  ></b-form-input>
                  <!-- Google authen -->
                  <p>{{ $t('GgAuthen.25') }}</p>
                  <b-form-input
                    v-model="formTransfer.auth"
                    required
                  ></b-form-input>
                  <!-- Btn - withdraw -->
                  <div class="custom-btn">
                    <button
                      type="submit"
                      class="button button-normal mt-3"
                      v-if="showBtnTransfer"
                    >
                      <div class="button-inner">{{ $t('Transaction.57') }}</div>
                    </button>
                    <button
                      type="button"
                      class="button button-normal mt-3 btn-disable"
                      v-else
                    >
                      <div class="button-inner">{{ $t('Transaction.57') }}</div>
                    </button>
                  </div>
                </b-form>
              </div>
            </template>
            <!-- Notification -->
            <!-- <div class="action-notification">
              <p>
                Vì lý do bảo mật, bất kỳ yêu cầu rút tiền lớn hoặc đáng ngờ nào
                sẽ được kiểm tra và xử lý một cách thủ công. Quá trình đánh giá
                này thường mất từ 1-6 tiếng, và trong một số trường hợp nhất
                định có thể sẽ mất nhiều thời gian hơn. Như mọi khi, chúng tôi
                đánh giá cao sự kiên nhẫn của bạn!
              </p>
            </div> -->
          </div>

          <div class="transaction-bottom" v-if="false">
            <div class="transaction-bottom-flex">
              <div class="cont">{{ $t('Transaction.67') }}.</div>
              <button
                class="button button-normal"
                @click="handleClickShowAuthen"
              >
                <div class="button-inner">{{ $t('Transaction.68') }}</div>
              </button>
            </div>
          </div>
        </template>
        <!-- Swap -->
        <template v-if="action === 4">
          <div class="action-withdraw">
            <div class="action-withdraw-header">
              <div class="box">
                <div class="type">
                  <template v-for="(coin, name, i) in listCoins">
                    <button
                      :key="'coin-swap' + i"
                      :class="activeCoin === i + 1 ? 'active' : ''"
                      v-if="coin.Swap == true"
                      @click="
                        activeCoin = i + 1;
                        formWithdraw.ecosystem = '';
                        fee = '';
                        formWithdraw.amount = '';
                        amountFee = '';
                        getCoinTo(coin.ID);
                      "
                    >
                      {{ coin.Name }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <template v-for="(coin, name, i) in listCoins">
              <div
                class="action-withdraw-qrcode"
                v-if="activeCoin === i + 1 && coin.Withdraw.length > 0"
                :key="'swap' + i"
              >
                <b-form @submit.prevent="onSwap(coin.ID)">
                  <!-- Coin from -->
                  <p>{{ $t('Transaction.80') }}</p>
                  <b-form-input :value="coin.Name" readonly></b-form-input>
                  <!-- Coin to -->
                  <p>{{ $t('Transaction.71') }}</p>
                  <template v-for="coin in listCoins">
                    <b-form-input
                      v-if="coin.ID === formSwap.coin_from"
                      :key="'coin-to' + coin.ID"
                      :value="coin.Name"
                      readonly
                    ></b-form-input>
                  </template>
                  <!-- Max -->
                  <p>{{ $t('Transaction.81') }}</p>
                  <b-form-input
                    :value="
                      TruncateToDecimals(
                        listCoins[coin.Key].MaxSwap / coin.rate + 0,
                        '',
                        4
                      )
                    "
                    readonly
                  ></b-form-input>
                  <!-- Min -->
                  <p>{{ $t('Transaction.74') }}</p>
                  <b-form-input
                    :value="
                      TruncateToDecimals(
                        listCoins[coin.Key].MinSwap / coin.rate + 0,
                        '',
                        4
                      )
                    "
                    readonly
                  ></b-form-input>
                  <!-- Rate -->
                  <p>{{ $t('Transaction.75') }}</p>
                  <b-form-input
                    :value="TruncateToDecimals(coin.rate, '', 4)"
                    readonly
                  ></b-form-input>
                  <!-- Amount -->
                  <p>{{ $t('Transaction.76') }}</p>
                  <b-form-input
                    type="number"
                    v-model="formSwap.amount"
                    v-on:keyup="keyupChangeAmount(coin.Key, coin.rate)"
                    required
                  ></b-form-input>
                  <!-- Fee -->
                  <p>
                    {{ $t('Transaction.77') }} (
                    {{ listCoins[coin.Key].FeeSwap * 100 }}% )
                  </p>
                  <b-form-input
                    :value="TruncateToDecimals(amountFeeTransfer, '', 4)"
                    readonly
                  ></b-form-input>
                  <!-- Actually received -->
                  <p>{{ $t('Transaction.82') }}</p>
                  <b-form-input
                    :value="TruncateToDecimals(amountReceiveTransfer, '', 4)"
                    readonly
                  ></b-form-input>
                  <!-- Btn - withdraw -->
                  <div class="custom-btn">
                    <button
                      type="submit"
                      class="button button-normal mt-3"
                      v-if="showBtnSwap"
                    >
                      <div class="button-inner">{{ $t('Transaction.83') }}</div>
                    </button>
                    <button
                      type="button"
                      class="button button-normal mt-3 btn-disable"
                      v-else
                    >
                      <div class="button-inner">{{ $t('Transaction.83') }}</div>
                    </button>
                  </div>
                </b-form>
              </div>
            </template>
            <!-- Notification -->
            <div class="action-notification">
              <p>
                {{ $t('Transaction.84') }}
              </p>
            </div>
          </div>

          <div class="transaction-bottom" v-if="false">
            <div class="transaction-bottom-flex">
              <div class="cont">{{ $t('Transaction.67') }}.</div>
              <button
                class="button button-normal"
                @click="handleClickShowAuthen"
              >
                <div class="button-inner">{{ $t('Transaction.68') }}</div>
              </button>
            </div>
          </div>
        </template>
        <!-- Game -->
        <template v-if="action === 5">
          <div class="listGame">
            <div class="action-game-header">
              <div class="box">
                <div class="type">
                  <template v-for="(game, i) in listGamePlaying">
                    <button
                      :key="'game' + i"
                      :class="activeCoin === i + 1 ? 'active' : ''"
                      @click="
                        activeCoin = i + 1;
                        formWithdraw.ecosystem = '';
                        fee = '';
                        formWithdraw.amount = '';
                        amountFee = 0;
                        showNoticePlayAgin = 0;
                        showLoginGameAgin = 1;
                        showBtnPlayGameAgin = 0;
                        showChangePassEvolution = 0;
                      "
                    >
                      {{ game.name }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <template v-for="(game, i) in listGamePlaying">
              <div
                class="action-game-button"
                v-if="activeCoin === i + 1"
                :key="'action-game-button' + i"
              >
                <!-- !game.comingSoon -->
                <template v-if="false">
                  <div>
                    <button
                      class="button bg-withdraw"
                      v-if="game.action"
                      @click="
                        showDepositGame = i + 1;
                        showWithdrawGame = 0;
                      "
                    >
                      {{ $t('Transaction.55') }}
                    </button>
                    <button
                      class="button bg-deposit"
                      v-if="game.action"
                      @click="
                        showWithdrawGame = i + 1;
                        showDepositGame = 0;
                      "
                    >
                      {{ $t('Transaction.56') }}
                    </button>
                    <a @click="chooseGameGetUrl(game.href)"
                      ><button class="button bg-play" v-if="game.href">
                        {{ $t('Transaction.86') }}
                      </button>
                    </a>
                  </div>
                  <!-- Deposit -->
                  <div
                    class="form-deposit-game"
                    v-if="showDepositGame === i + 1"
                  >
                    <h5>{{ $t('Transaction.55') }} {{ game.name }}</h5>
                    <b-form @submit.prevent="onDepositGame(game.name)">
                      <!-- Số lượng -->
                      <p>{{ $t('Transaction.76') }}</p>
                      <b-form-input
                        type="number"
                        v-model.number="formDepositGame.amount"
                        required
                      ></b-form-input>
                      <!-- Mật khẩu hiện tại -->
                      <p>{{ $t('InforUser.45') }}</p>
                      <b-form-input
                        v-model="formDepositGame.password"
                        required
                        type="password"
                      ></b-form-input>
                      <button class="button">{{ $t('Transaction.55') }}</button>
                    </b-form>
                  </div>
                  <!-- Rút -->
                  <div
                    class="form-withdraw-game"
                    v-if="showWithdrawGame === i + 1"
                  >
                    <h5>{{ $t('Transaction.56') }} {{ game.name }}</h5>
                    <b-form @submit.prevent="onWithdrawGame(game.name)">
                      <!-- Số lượng -->
                      <p>{{ $t('Transaction.76') }}</p>
                      <b-form-input
                        type="number"
                        v-model.number="formWithdrawGame.amount"
                        required
                      ></b-form-input>
                      <!-- Mật khẩu hiện tại -->
                      <p>{{ $t('InforUser.45') }}</p>
                      <b-form-input
                        v-model="formWithdrawGame.password"
                        required
                        type="password"
                      ></b-form-input>
                      <button class="button">{{ $t('Transaction.56') }}</button>
                    </b-form>
                  </div>
                </template>
                <!-- V-else -->
                <template v-if="false">
                  <span class="text-danger font-weight-bold"
                    >{{ $t('Transaction.85') }} !
                  </span>
                </template>
                <!-- VM555 -->
                <template v-if="game.name === 'Casino WM555'">
                  <div class="Casino-WM555-btnGroup">
                    <button
                      class="button bg-withdraw"
                      v-if="GameStatus.WM555 == 1"
                      @click="
                        showDepositGame = 3;
                        showNoticePlayVM555 = 0;
                        showWithdrawGame = 0;
                        showChangePassVM555 = 0;
                        showRegisterVM555 = 0;
                      "
                    >
                      {{ $t('Transaction.55') }}
                    </button>
                    <button
                      class="button bg-deposit"
                      v-if="GameStatus.WM555 == 1"
                      @click="
                        showWithdrawGame = 3;
                        showDepositGame = 0;
                        showNoticePlayVM555 = 0;
                        showChangePassVM555 = 0;
                        showRegisterVM555 = 0;
                      "
                    >
                      {{ $t('Transaction.56') }}
                    </button>
                    <button
                      class="button bg-deposit"
                      v-if="GameStatus.WM555 != 1"
                      @click="
                        showWithdrawGame = 0;
                        showDepositGame = 0;
                        showRegisterVM555 = 1;
                        showChangePassVM555 = 0;
                      "
                    >
                      {{ $t('header.2') }}
                    </button>
                    <span v-else>
                      <button
                        v-if="ChangePasswordStatus.WM555 == 1"
                        class="button"
                        @click="
                          showChangePassVM555 = 1;
                          showNoticePlayVM555 = 0;
                          showWithdrawGame = 0;
                          showDepositGame = 0;
                        "
                      >
                        {{ $t('InforUser.39') }}</button
                      ><button
                        v-if="showPlayGameVM555 === 1"
                        class="button bg-play"
                        @click="
                          showNoticePlayVM555 = 1;
                          showChangePassVM555 = 0;
                          showWithdrawGame = 0;
                          showPlayGameVM555 = 0;
                        "
                      >
                        {{ $t('Transaction.86') }}
                      </button>
                    </span>
                  </div>
                  <!-- Deposit VM555 -->
                  <div class="form-deposit-game" v-if="showDepositGame === 3">
                    <h5>{{ $t('Transaction.55') }} {{ game.name }}</h5>
                    <b-form @submit.prevent="onDepositGameVM55()">
                      <!-- Số lượng -->
                      <p>{{ $t('Transaction.76') }}</p>
                      <b-form-input
                        type="number"
                        v-model.number="formDepositVM555.amount"
                        required
                      ></b-form-input>
                      <button class="button">{{ $t('Transaction.55') }}</button>
                    </b-form>
                  </div>
                  <!-- Rút -->
                  <div class="form-withdraw-game" v-if="showWithdrawGame === 3">
                    <h5>{{ $t('Transaction.56') }} {{ game.name }}</h5>
                    <b-form @submit.prevent="onWithdrawGameVM555()">
                      <!-- Số lượng -->
                      <p>{{ $t('Transaction.76') }}</p>
                      <b-form-input
                        type="number"
                        v-model.number="formWithdrawVM555.amount"
                        required
                      ></b-form-input>
                      <button class="button">{{ $t('Transaction.56') }}</button>
                    </b-form>
                  </div>
                  <!-- Register VM555 -->
                  <div
                    class="form-resgiter-vm555"
                    v-if="showRegisterVM555 === 1"
                  >
                    <b-form @submit.prevent="onRegisterVM555()">
                      <h5>{{ $t('Transaction.87') }}</h5>
                      <!-- User name -->
                      <p>{{ $t('Message.262') }}</p>
                      <b-form-input
                        type="text"
                        :value="'now' + Info.id"
                        readonly
                      ></b-form-input>
                      <!-- password -->
                      <p>{{ $t('InforUser.49') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formRegisterVM555.password"
                        required
                      ></b-form-input>
                      <!-- password confirm -->
                      <p>{{ $t('Transaction.88') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formRegisterVM555.password_confirm"
                        required
                      ></b-form-input>
                      <button class="button" type="submit">
                        {{ $t('header.2') }}
                      </button>
                      <p class="notification text-warning">
                        {{ $t('Message.263') }}: {{ $t('Transaction.89') }}.
                      </p>
                    </b-form>
                  </div>
                  <!-- Note When Play -->
                  <div
                    class="notificationPlay"
                    v-if="showNoticePlayVM555 === 1"
                  >
                    <h3>{{ $t('Transaction.90') }}</h3>
                    <p>
                      {{ $t('Transaction.91') }}
                      <b>{{ 'now' + Info.id }}</b
                      >. {{ $t('Transaction.92') }}.
                    </p>
                    <div class="group-btn">
                      <a @click="chooseGameGetUrl(Game.WM555)"
                        ><button class="button">
                          {{ $t('Transaction.86') }}
                        </button></a
                      >
                      <button
                        class="button"
                        @click="
                          showNoticePlayVM555 = 0;
                          showPlayGameVM555 = 1;
                        "
                      >
                        {{ $t('Transaction.93') }}
                      </button>
                    </div>
                  </div>
                  <!-- Change pass VM555 -->
                  <div
                    class="form-deposit-game"
                    v-if="showChangePassVM555 === 1"
                  >
                    <h5>{{ $t('InforUser.39') }} {{ game.name }}</h5>
                    <b-form @submit.prevent="onChangePasswordWM555()">
                      <!-- Password -->
                      <p>{{ $t('InforUser.49') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formChangePassGameVM555.password"
                        required
                      ></b-form-input>
                      <!-- new pass -->
                      <p>{{ $t('InforUser.46') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formChangePassGameVM555.new_password"
                        required
                      ></b-form-input>
                      <!-- Password confirmation -->
                      <p>{{ $t('Transaction.88') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formChangePassGameVM555.confirm_password"
                        required
                      ></b-form-input>
                      <span>
                        <button class="button" type="submit">
                          {{ $t('InforUser.39') }}
                        </button>
                        <button
                          class="button bg-play"
                          @click="showChangePassVM555 = 0"
                        >
                          {{ $t('Transaction.93') }}
                        </button>
                      </span>
                    </b-form>
                  </div>
                </template>
                <!-- Agin Sportbook -->
                <template
                  v-if="
                    game.name === 'Agin Sport Book' ||
                      game.name === 'Agin Fish Shooter' ||
                      game.name === 'Agin Slot'
                  "
                >
                  <div class="Casino-WM555-btnGroup">
                    <button
                      class="button bg-withdraw"
                      v-if="GameStatus.AGINSPOSTBOOK == 1"
                      @click="
                        showDepositGame = 4;
                        showNoticePlayAgin = 0;
                        showWithdrawGame = 0;
                        showChangePassAgin = 0;
                        showLoginGameAgin = 1;
                      "
                    >
                      {{ $t('Transaction.55') }}
                    </button>
                    <button
                      class="button bg-deposit"
                      v-if="GameStatus.AGINSPOSTBOOK == 1"
                      @click="
                        showWithdrawGame = 4;
                        showDepositGame = 0;
                        showNoticePlayAgin = 0;
                        showChangePassAgin = 0;
                        showLoginGameAgin = 1;
                      "
                    >
                      {{ $t('Transaction.56') }}
                    </button>
                    <button
                      class="button bg-deposit"
                      v-if="GameStatus.AGINSPOSTBOOK != 1"
                      @click="
                        showWithdrawGame = 0;
                        showDepositGame = 0;
                        showRegisterAign = 1;
                        showChangePassAgin = 0;
                      "
                    >
                      {{ $t('header.2') }}
                    </button>
                    <span v-else>
                      <button
                        v-if="ChangePasswordStatus.AGINSPOSTBOOK === 1"
                        class="button"
                        @click="
                          showChangePassAgin = 1;
                          showNoticePlayAgin = 0;
                          showWithdrawGame = 0;
                          showDepositGame = 0;
                          showLoginGameAgin = 1;
                        "
                      >
                        {{ $t('InforUser.39') }}</button
                      ><button
                        v-if="showLoginGameAgin === 1"
                        class="button bg-play"
                        @click="
                          showNoticePlayAgin = 1;
                          showChangePassAgin = 0;
                          showDepositGame = 0;
                          showWithdrawGame = 0;
                          showLoginGameAgin = 0;
                        "
                      >
                        {{ $t('header.1') }}
                      </button>
                    </span>
                  </div>
                  <!-- Deposit agin -->
                  <div class="form-deposit-game" v-if="showDepositGame === 4">
                    <h5>{{ $t('Transaction.55') }} Agin</h5>
                    <b-form @submit.prevent="onDepositGameAgin()">
                      <!-- Số lượng -->
                      <p>
                        {{ $t('Transaction.76') }} (
                        {{ $t('Transaction.74') }} = 50$ )
                      </p>
                      <b-form-input
                        type="number"
                        v-model.number="formDepositAgin.amount"
                        required
                      ></b-form-input>
                      <p>{{ $t('InforUser.49') }}</p>
                      <b-form-input
                        type="password"
                        v-model.number="formDepositAgin.password"
                        required
                      ></b-form-input>
                      <button class="button">{{ $t('Transaction.55') }}</button>
                    </b-form>
                  </div>
                  <!-- withdraw agin -->
                  <div class="form-withdraw-game" v-if="showWithdrawGame === 4">
                    <h5>{{ $t('Transaction.56') }} Agin</h5>
                    <b-form @submit.prevent="onWithdrawGameAgin()">
                      <p>
                        {{ $t('Transaction.76') }} (
                        {{ $t('Transaction.74') }} = 50$ )
                      </p>
                      <b-form-input
                        type="number"
                        v-model.number="formWithdrawAgin.amount"
                        required
                      ></b-form-input>
                      <p>{{ $t('InforUser.49') }}</p>
                      <b-form-input
                        type="password"
                        v-model.number="formWithdrawAgin.password"
                        required
                      ></b-form-input>
                      <button class="button">{{ $t('Transaction.56') }}</button>
                    </b-form>
                  </div>
                  <!-- Register agin -->
                  <div
                    class="form-resgiter-vm555"
                    v-if="showRegisterAign === 1"
                  >
                    <b-form @submit.prevent="onRegisterAgin()">
                      <h5>{{ $t('header.2') }} Agin</h5>
                      <!-- User name -->
                      <p>{{ $t('Message.262') }}</p>
                      <b-form-input
                        type="text"
                        :value="'now_' + Info.id"
                        readonly
                      ></b-form-input>
                      <!-- password -->
                      <p>{{ $t('InforUser.49') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formRegisterAgin.password"
                        required
                      ></b-form-input>
                      <!-- password confirm -->
                      <p>{{ $t('Transaction.88') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formRegisterAgin.password_confirm"
                        required
                      ></b-form-input>
                      <button class="button" type="submit">
                        {{ $t('header.2') }}
                      </button>
                      <p class="notification text-warning">
                        {{ $t('Message.263') }}: {{ $t('Transaction.95') }}.
                      </p>
                    </b-form>
                  </div>
                  <!-- Note When Play -->
                  <div
                    class="notificationPlay form-deposit-game2"
                    v-if="showNoticePlayAgin === 1"
                  >
                    <p>{{ $t('Message.262') }}</p>
                    <b-form-input
                      type="text"
                      :value="'now_' + Info.id"
                      readonly
                    ></b-form-input>
                    <p>{{ $t('InforUser.49') }}</p>
                    <b-form-input
                      type="password"
                      v-model.number="formLoginAgin.password"
                      required
                    ></b-form-input>
                    <h3>{{ $t('Transaction.90') }}</h3>
                    <p>
                      {{ $t('Transaction.91') }}
                      <b>{{ 'now_' + Info.id }}</b
                      >. {{ $t('Transaction.92') }}.
                    </p>
                    <div class="group-btn">
                      <a @click="onLoginAgin(game.name)"
                        ><button class="button">{{ $t('header.1') }}</button></a
                      >
                      <button
                        class="button bg-play"
                        @click="
                          showNoticePlayAgin = 0;
                          showLoginGameAgin = 1;
                        "
                      >
                        {{ $t('Transaction.93') }}
                      </button>
                    </div>
                  </div>
                  <!-- Btn play -->
                  <div
                    class="notificationPlay"
                    v-if="showBtnPlayGameAgin === 1 && DataLoginAgin"
                  >
                    <h3>{{ $t('Transaction.90') }}</h3>
                    <p>
                      {{ $t('Transaction.91') }}
                      <b>{{ 'now_' + Info.id }}</b
                      >. {{ $t('Transaction.92') }}.
                    </p>
                    <div class="group-btn">
                      <a @click="chooseGameGetUrl(DataLoginAgin)"
                        ><button class="button">
                          {{ $t('Message.264') }}
                        </button></a
                      >
                    </div>
                  </div>
                  <!-- Change pass agin -->
                  <div
                    class="form-deposit-game2"
                    v-if="showChangePassAgin === 1"
                  >
                    <h5>{{ $t('InforUser.39') }} Agin</h5>
                    <b-form @submit.prevent="onChangePasswordAgin()">
                      <!-- Password -->
                      <p>{{ $t('InforUser.49') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formChangePassGameAgin.password"
                        required
                      ></b-form-input>
                      <!-- new pass -->
                      <p>{{ $t('InforUser.46') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formChangePassGameAgin.new_password"
                        required
                      ></b-form-input>
                      <!-- Password confirmation -->
                      <p>{{ $t('Transaction.88') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formChangePassGameAgin.confirm_password"
                        required
                      ></b-form-input>
                      <span>
                        <button class="button" type="submit">
                          {{ $t('InforUser.39') }}
                        </button>
                        <button
                          class="button bg-play"
                          @click="showChangePassAgin = 0"
                        >
                          {{ $t('Transaction.93') }}
                        </button>
                      </span>
                    </b-form>
                  </div>
                </template>
                <!-- Casino Evolution -->
                <template v-if="game.name === 'Casino Evolution'">
                  <div class="Casino-WM555-btnGroup">
                    <button
                      class="button bg-withdraw"
                      v-if="GameStatus.EVOLUTION == 1"
                      @click="
                        showDepositGame = 5;
                        showNoticePlayEvolution = 0;
                        showWithdrawGame = 0;
                        showChangePassEvolution = 0;
                        showLoginGameEvolution = 1;
                        showBtnPlayGameEvoluiton = 0;
                      "
                    >
                      {{ $t('Transaction.55') }}
                    </button>
                    <button
                      class="button bg-deposit"
                      v-if="GameStatus.EVOLUTION == 1"
                      @click="
                        showWithdrawGame = 5;
                        showDepositGame = 0;
                        showNoticePlayEvolution = 0;
                        showChangePassEvolution = 0;
                        showLoginGameEvolution = 1;
                        showBtnPlayGameEvoluiton = 0;
                      "
                    >
                      {{ $t('Transaction.56') }}
                    </button>
                    <button
                      class="button bg-deposit"
                      v-if="GameStatus.EVOLUTION != 1"
                      @click="
                        showWithdrawGame = 0;
                        showDepositGame = 0;
                        showRegisterEvolution = 1;
                        showChangePassEvolution = 0;
                      "
                    >
                      {{ $t('header.2') }}
                    </button>
                    <span v-else>
                      <button
                        v-if="ChangePasswordStatus.EVOLUTION === 1"
                        class="button"
                        @click="
                          showChangePassEvolution = 1;
                          showNoticePlayEvolution = 0;
                          showWithdrawGame = 0;
                          showDepositGame = 0;
                          showLoginGameEvolution = 1;
                          showBtnPlayGameEvoluiton = 0;
                        "
                      >
                        {{ $t('InforUser.39') }}</button
                      ><button
                        v-if="showLoginGameEvolution === 1"
                        class="button bg-play"
                        @click="
                          showNoticePlayEvolution = 1;
                          showChangePassEvolution = 0;
                          showDepositGame = 0;
                          showWithdrawGame = 0;
                          showLoginGameEvolution = 0;
                        "
                      >
                        {{ $t('header.1') }}
                      </button>
                    </span>
                  </div>
                  <!-- Deposit evolution -->
                  <div class="form-deposit-game" v-if="showDepositGame === 5">
                    <h5>{{ $t('Transaction.55') }} Evoluton</h5>
                    <b-form @submit.prevent="onDepositGameEvolution()">
                      <!-- Số lượng -->
                      <p>
                        {{ $t('Transaction.76') }} (
                        {{ $t('Transaction.74') }} = 50$ )
                      </p>
                      <b-form-input
                        type="number"
                        v-model.number="formDepositEvolution.amount"
                        required
                      ></b-form-input>
                      <p>{{ $t('InforUser.49') }}</p>
                      <b-form-input
                        type="password"
                        v-model.number="formDepositEvolution.password"
                        required
                      ></b-form-input>
                      <button class="button">{{ $t('Transaction.55') }}</button>
                    </b-form>
                  </div>
                  <!-- withdraw evolution -->
                  <div class="form-withdraw-game" v-if="showWithdrawGame === 5">
                    <h5>{{ $t('Transaction.56') }} Evolution</h5>
                    <b-form @submit.prevent="onWithdrawGameEvolution()">
                      <p>
                        {{ $t('Transaction.76') }} (
                        {{ $t('Transaction.74') }} = 50$ )
                      </p>
                      <b-form-input
                        type="number"
                        v-model.number="formWithdrawEvolution.amount"
                        required
                      ></b-form-input>
                      <p>{{ $t('InforUser.49') }}</p>
                      <b-form-input
                        type="password"
                        v-model.number="formWithdrawEvolution.password"
                        required
                      ></b-form-input>
                      <button class="button">{{ $t('Transaction.56') }}</button>
                    </b-form>
                  </div>
                  <!-- Register Evolution -->
                  <div
                    class="form-resgiter-vm555"
                    v-if="showRegisterEvolution === 1"
                  >
                    <b-form @submit.prevent="onRegisterEvolution()">
                      <h5>{{ $t('header.2') }} {{ game.name }}</h5>
                      <!-- User name -->
                      <p>{{ $t('Message.262') }}</p>
                      <b-form-input
                        type="text"
                        :value="'now_' + Info.id"
                        readonly
                      ></b-form-input>
                      <!-- password -->
                      <p>{{ $t('InforUser.49') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formRegisterEvolution.password"
                        required
                      ></b-form-input>
                      <!-- password confirm -->
                      <p>{{ $t('Transaction.88') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formRegisterEvolution.password_confirm"
                        required
                      ></b-form-input>
                      <button class="button" type="submit">
                        {{ $t('header.2') }}
                      </button>
                      <p class="notification text-warning">
                        {{ $t('Message.263') }}: {{ $t('Transaction.95') }}.
                      </p>
                    </b-form>
                  </div>
                  <!-- Note When Play -->
                  <div
                    class="notificationPlay form-deposit-game2"
                    v-if="showNoticePlayEvolution === 1"
                  >
                    <p>{{ $t('Message.262') }}</p>
                    <b-form-input
                      type="text"
                      :value="'now_' + Info.id"
                      readonly
                    ></b-form-input>
                    <p>{{ $t('InforUser.49') }}</p>
                    <b-form-input
                      type="password"
                      v-model.number="formLoginEvolution.password"
                      required
                    ></b-form-input>
                    <h3>{{ $t('Transaction.90') }}</h3>
                    <p>
                      {{ $t('Transaction.91') }}
                      <b>{{ 'now_' + Info.id }}</b
                      >. {{ $t('Transaction.92') }}.
                    </p>
                    <div class="group-btn">
                      <a @click="onLoginEvolution(game.name)"
                        ><button class="button">{{ $t('header.1') }}</button></a
                      >
                      <button
                        class="button bg-play"
                        @click="
                          showNoticePlayEvolution = 0;
                          showLoginGameEvolution = 1;
                        "
                      >
                        {{ $t('Transaction.93') }}
                      </button>
                    </div>
                  </div>
                  <!-- Btn play -->
                  <div
                    class="notificationPlay"
                    v-if="showBtnPlayGameEvoluiton === 1 && DataLoginEvolution"
                  >
                    <h3>{{ $t('Transaction.90') }}</h3>
                    <p>
                      {{ $t('Transaction.91') }}
                      <b>{{ 'now_' + Info.id }}</b
                      >. {{ $t('Transaction.92') }}.
                    </p>
                    <div class="group-btn">
                      <a @click="chooseGameGetUrl(DataLoginEvolution)"
                        ><button class="button">
                          {{ $t('Message.264') }}
                        </button></a
                      >
                    </div>
                  </div>
                  <!-- Change pass Evolution -->
                  <div
                    class="form-deposit-game"
                    v-if="showChangePassEvolution === 1"
                  >
                    <h5>{{ $t('InforUser.39') }} {{ game.name }}</h5>
                    <b-form @submit.prevent="onChangePasswordEvolution()">
                      <!-- Password -->
                      <p>{{ $t('InforUser.49') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formChangePassGameEvolution.password"
                        required
                      ></b-form-input>
                      <!-- new pass -->
                      <p>{{ $t('InforUser.46') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formChangePassGameEvolution.new_password"
                        required
                      ></b-form-input>
                      <!-- Password confirmation -->
                      <p>{{ $t('Transaction.88') }}</p>
                      <b-form-input
                        type="password"
                        v-model="formChangePassGameEvolution.confirm_password"
                        required
                      ></b-form-input>
                      <span>
                        <button class="button" type="submit">
                          {{ $t('InforUser.39') }}
                        </button>
                        <button
                          class="button bg-cancel"
                          @click="showChangePassEvolution = 0"
                        >
                          {{ $t('Transaction.93') }}
                        </button>
                      </span>
                    </b-form>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
        <div class="transaction-bottom" v-if="!Auth.enabled && action !== 6">
          <div class="transaction-bottom-flex">
            <div class="cont">{{ $t('Transaction.67') }}.</div>
            <button class="button button-normal" @click="handleClickShowAuthen">
              <div class="button-inner">{{ $t('Transaction.68') }}</div>
            </button>
          </div>
        </div>
        <!-- Bonus -->
        <template v-if="action === 6">
          <div class="action-withdraw">
            <div class="action-withdraw-qrcode">
              <b-form @submit.prevent="handleWithdrawBonus">
                <p>{{ $t('Transaction.96') }}</p>
                <b-form-input
                  v-if="Agency.Static && Agency.Static.balanBonus"
                  v-model="Agency.Static.balanBonus"
                  readonly
                ></b-form-input>
                <p>{{ $t('Transaction.97') }}</p>
                <b-form-input
                  v-if="Agency.Static && Agency.Static.totalTradeBonus"
                  v-model="Agency.Static.totalTradeBonus"
                  readonly
                ></b-form-input>
                <!-- <p>{{ $t('Transaction.98') }}</p>
                <b-form-input
                  v-if="Agency.Static && Agency.Static.maxWithdtawBonus"
                  v-model="Agency.Static.maxWithdtawBonus"
                  readonly
                ></b-form-input> -->
                <p>Amount ( Min = 50 USDT )</p>
                <b-form-input
                  v-model="amountWithdraw"
                  required
                  type="number"
                  @keyup="onKeyUpChangeBtnWithdrawBonus"
                ></b-form-input>
                <p>{{ $t('InforUser.49') }}</p>
                <b-form-input
                  v-model.number="passwordWithdraw"
                  type="password"
                  :placeholder="$t('InforUser.49')"
                  @keyup="onKeyUpChangeBtnWithdrawBonus"
                ></b-form-input>
                <div class="custom-btn">
                  <button
                    v-if="showBtnWithdrawBonus"
                    type="submit"
                    class="button mt-3"
                    style="margin-left:10px"
                  >
                    {{ $t('Transaction.56') }}
                  </button>
                  <button
                    v-else
                    class="button mt-3 btn-disable"
                    style="margin-left:10px"
                    type="button"
                  >
                    {{ $t('Transaction.56') }}
                  </button>
                </div>
              </b-form>
              <div class="action-notification">
                <p>
                  {{ $t('Transaction.99') }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </b-modal>
    <GgAuthen @clickHideAuthen="clickHideAuthen" :type="type" />
    <modalCopy :content="content" :type="`transaction-${type}`" />
    <!-- modal withdrawBonus -->
    <b-modal
      id="withdrawBonus-wallet"
      :width="'100%'"
      :height="'auto'"
      :adaptive="true"
      :styles="
        'overflow-y: auto;max-width:400px;max-height:85vh;right:0;margin:auto'
      "
      hide-footer
      hide-header
    >
      <div class="title">{{ $t('Transaction.100') }}</div>
      <form class="form-horizontal" style="padding-top: 15px">
        <p
          style="color: #f44336;text-align:left;font-size: 13px;padding:0 10px;margin-bottom:5px"
        >
          {{ $t('Transaction.99') }}.
        </p>
        <div class="form-group" style="margin: 0 0 0px">
          <div
            class="col-xs-12 text-left"
            v-if="Agency.Static && Agency.Static.balanBonus"
          >
            <p>{{ $t('Win_Loss.201') }}</p>
            <input
              v-model="Agency.Static.balanBonus"
              autocomplete="off"
              type="text"
              name="address"
              readonly
              class="form-control"
              :placeholder="$t('InforUser.37')"
            />
          </div>
        </div>
        <div
          class="form-group"
          style="margin: 0 0 0px"
          v-if="Agency.Static && Agency.Static.maxWithdtawBonus"
        >
          <div class="col-xs-12 text-left">
            <p>{{ $t('Transaction.98') }}</p>
            <input
              v-model="Agency.Static.maxWithdtawBonus"
              autocomplete="off"
              type="text"
              name="address"
              readonly
              class="form-control"
              :placeholder="$t('InforUser.37')"
            />
          </div>
        </div>
        <!-- <div class="form-group" style="margin: 0 0 0px">
          <div class="col-xs-12 text-left">
            <p>Amount</p>
            <input
              v-model.number="amountWithdraw"
              type="number"
              step="0.001"
              name="amount"
              class="form-control"
              placeholder="Amount"
            />
          </div>
        </div> -->
        <div class="form-group" style="margin: 0 0 0px">
          <div class="col-xs-12 text-left">
            <p>{{ $t('InforUser.49') }}</p>
            <input
              v-model.number="passwordWithdraw"
              type="password"
              name="passwordWithdraw"
              class="form-control"
              :placeholder="$t('InforUser.49')"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12 text-center custom-btn">
            <button
              style="margin-top:10px"
              type="button"
              @click="handleWithdrawBonus"
            >
              {{ $t('Transaction.56') }}
            </button>
            <button
              style="margin-top:10px;margin-left:5px"
              type="button"
              @click="handleCancelModal"
            >
              {{ $t('Transaction.93') }}
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import GgAuthen from './GgAuthen.vue';
  import modalCopy from './PopupCopy.vue';
  import Iframe from '@/components/game/Iframe.vue';

  export default {
    props: ['type', 'action'],
    components: { GgAuthen, modalCopy, Iframe },
    data() {
      return {
        activeCoin: 0,
        formWithdraw: {
          ecosystem: '',
          address: '',
          auth: '',
          token: '',
          amount: 10,
          coin_to: '',
        },
        isWithdraw: false,
        // showBtnWithdraw: false,
        formTransfer: {
          email: '',
          amount: '',
          auth: '',
          coin: '',
          token: '',
        },
        isTransfer: false,
        // showBtnTransfer: false,
        formSwap: {
          amount: '',
          coin_from: '',
          coin_to: '',
          token: '',
        },
        showBtnSwap: false,
        formDepositGame: {
          amount: '',
          password: '',
          typeDeposit: '',
          token: '',
        },
        formWithdrawGame: {
          amount: '',
          password: '',
          typeWithdraw: '',
          token: '',
        },
        // VM555
        formRegisterVM555: {
          password_confirm: '',
          password: '',
          token: '',
        },
        formDepositVM555: {
          amount: '',
          token: '',
        },
        formWithdrawVM555: {
          amount: '',
          token: '',
        },
        formChangePassGameVM555: {
          password: '',
          new_password: '',
          confirm_password: '',
          token: '',
        },
        // Agin
        formRegisterAgin: {
          password_confirm: '',
          password: '',
          token: '',
        },
        formDepositAgin: {
          amount: '',
          password: '',
          token: '',
        },
        formWithdrawAgin: {
          amount: '',
          password: '',
          token: '',
        },
        formChangePassGameAgin: {
          password: '',
          new_password: '',
          confirm_password: '',
          token: '',
        },
        formLoginAgin: {
          password: '',
          token: '',
        },
        // Evolution
        formRegisterEvolution: {
          password_confirm: '',
          password: '',
          token: '',
        },
        formDepositEvolution: {
          amount: '',
          password: '',
          token: '',
        },
        formWithdrawEvolution: {
          amount: '',
          password: '',
          token: '',
        },
        formChangePassGameEvolution: {
          password: '',
          new_password: '',
          confirm_password: '',
          token: '',
        },
        formLoginEvolution: {
          password: '',
          token: '',
        },
        // -------------------------
        fee: 0,
        amountFee: 0,
        feeEcosystems: 0,
        amountReceiveTransfer: 0,
        amountToTransfer: 0,
        amountFeeTransfer: 0,
        listGamePlaying: [
          // {
          //   name: 'Casino SA',
          //   href: 'https://casino.blackchip.net',
          //   action: true,
          //   comingSoon: '',
          // },
          { name: 'Casino WM555', href: '', action: '', comingSoon: '' },
          // {
          //   name: 'Slots Game',
          //   href: 'https://fish.blackchip.net?slot',
          //   action: true,
          //   comingSoon: '',
          // },
          // {
          //   name: 'Fish Shooter',
          //   href: 'https://fish.blackchip.net?fish',
          //   action: true,
          //   comingSoon: '',
          // },
          // {
          //   name: 'Sport Book',
          //   href: 'https://sportbook.blackchip.net',
          //   action: true,
          //   comingSoon: '',
          // },
          {
            name: 'Agin Sport Book',
            href: '',
            action: '',
            comingSoon: '',
          },
          {
            name: 'Agin Fish Shooter',
            href: '',
            action: '',
            comingSoon: '',
          },
          {
            name: 'Agin Slot',
            href: '',
            action: '',
            comingSoon: '',
          },
          {
            name: 'Casino Evolution',
            href: '',
            action: '',
            comingSoon: '',
          },
          // { name: 'Binary Options', href: '', action: '', comingSoon: true },
        ],
        showDepositGame: 0,
        showWithdrawGame: 0,
        // VM555
        showRegisterVM555: 0,
        showNoticePlayVM555: 0,
        showChangePassVM555: 0,
        showPlayGameVM555: 1,
        // Agin
        showRegisterAign: 0,
        showNoticePlayAgin: 0,
        showChangePassAgin: 0,
        showBtnPlayGameAgin: 0,
        showLoginGameAgin: 1,
        // Evolution
        showRegisterEvolution: 0,
        showNoticePlayEvolution: 0,
        showChangePassEvolution: 0,
        showLoginGameEvolution: 1,
        showBtnPlayGameEvoluiton: 0,
        // ----------------------
        content: '',
        showIframe: false,
        iframe: {
          src: '',
          status: false,
        },
        amountWithdraw: 0,
        passwordWithdraw: '',
        showBtnWithdrawBonus: false,
      };
    },
    computed: {
      ...mapState(['isLogin']),
      ...mapGetters({
        Agency: 'dashboard/Agency',
        Auth: 'auth/Auth',
        listCoins: 'wallet/ListCoin',
        CoinDeposit: 'wallet/CoinDeposit',
        ecosystems: 'wallet/ListEcosystem',
        Balance: 'dashboard/Balance',
        GameStatus: 'dashboard/GameStatus',
        Info: 'dashboard/Info',
        ChangePasswordStatus: 'dashboard/ChangePasswordStatus',
        Game: 'dashboard/Game',
        DataLoginAgin: 'wallet/DataLoginAgin',
        DataLoginEvolution: 'wallet/DataLoginEvolution',
      }),
      showBtnTransfer() {
        if (
          this.formTransfer.email === '' ||
          this.formTransfer.amount === '' ||
          this.formTransfer.auth === ''
        ) {
          return false;
        }
        return true;
      },
      showBtnWithdraw() {
        if (
          // this.formWithdraw.ecosystem === '' ||
          this.formWithdraw.address === '' ||
          this.formWithdraw.auth === '' ||
          this.formWithdraw.amount === ''
        ) {
          return false;
        }
        return true;
      },
    },
    methods: {
      ...mapMutations(['onLoad', 'outLoad', 'getGame']),
      chooseGameGetUrl(url) {
        this.iframe.src = url;
        this.iframe.status = true;
        this.$store.commit('getGame', this.iframe);
        this.$bvModal.hide(`modal-transaction-${this.type}`);
        this.showBtnPlayGameAgin = 0;
        this.showLoginGameAgin = 1;
      },
      changeShowIframeParent(status) {
        this.showIframe = status;
      },
      handleClickShowAuthen() {
        this.$bvModal.show(`modal-authen-${this.type}`);
        this.$bvModal.hide(`modal-transaction-${this.type}`);
      },
      clickHideAuthen() {
        this.$bvModal.hide(`modal-authen-${this.type}`);
        this.$bvModal.show(`modal-transaction-${this.type}`);
      },
      TruncateToDecimals(num, mah, dec = 2) {
        // eslint-disable-next-line no-undef
        var numeral = require('numeral');
        var calcDec = Math.pow(10, dec);
        var newNum = Math.trunc(num * calcDec) / calcDec;
        const d = newNum.toString().split('.');
        return `${numeral(d[0]).format(0, 0)}${d[1] ? '.' + d[1] : ''}`;
      },
      // Deposit
      getDeposit(id) {
        this.$store.dispatch('wallet/req_getDeposit', id);
      },
      // -----------------
      // With draw
      onChangeEcosystem(event, coinKey) {
        // if (
        //   this.formWithdraw.ecosystem === '' ||
        //   this.formWithdraw.address === '' ||
        //   this.formWithdraw.auth === '' ||
        //   this.formWithdraw.amount === ''
        // ) {
        //   this.showBtnWithdraw = false;
        // } else this.showBtnWithdraw = true;
        for (let coin in this.listCoins) {
          if (coin === coinKey) {
            var entry = this.listCoins[coin];
            for (var a in entry) {
              if (a === 'WithdrawFeeEcoSystem') {
                if (event === 'BO') {
                  this.fee = entry[a].BO;
                }
                if (event === 'Out') {
                  this.fee = entry[a].Out;
                }
                if (event === 'System') {
                  this.fee = entry[a].System;
                }
              }
            }
          }
        }
        this.amountFee = (this.formWithdraw.amount * this.fee) / 100;
      },
      changeAmount(event, coinKey) {
        this.formWithdraw.amount = event.target.value;
        for (let coin in this.listCoins) {
          if (coin === coinKey) {
            var entry = this.listCoins[coin];
            for (var a in entry) {
              console.log(a);
              if (a === 'WithdrawFeeEcoSystem') {
                console.log(entry[a].Out);
                this.fee = entry[a].Out;
              }
            }
          }
        }
        this.amountFee = (this.formWithdraw.amount * this.fee) / 100;
      },
      async onWithdraw(coinId) {
        if (this.isWithdraw) {
          this.$toastr.e(this.$t('Message.244'), this.$t('Message.238'));
          return;
        }
        if (!this.Info.connectAddressMetamask) {
          this.$toastr.e(this.$t('Message.245'), this.$t('Message.238'));
          return;
        }
        this.isWithdraw = true;
        this.formWithdraw.ecosystem = 'Out';
        this.formWithdraw.coin_to = coinId.toString();
        this.formWithdraw.amount = +this.formWithdraw.amount;
        let WAValidator = require('multicoin-address-validator');
        let mainnet = '';
        switch (coinId) {
          case 3:
            mainnet = 'trx';
            break;
          case 5:
            mainnet = 'eth';
            break;
          case 6:
            mainnet = 'trx';
            break;
          case 8:
            mainnet = 'eth';
            break;
          case 11:
            mainnet = 'eth';
            break;
          case 4:
            mainnet = 'eth';
            break;
          case 7:
            mainnet = 'eth';
            break;
        }
        let valid = WAValidator.validate(this.formWithdraw.address, mainnet);
        if (valid) {
          this.onLoad();
          this.formWithdraw.token = await this.genarateCaptChaV3('withdraw');
          this.$store.dispatch('wallet/req_withdraw', this.formWithdraw);
          await this.sleep(2000);
          this.isWithdraw = false;
        } else {
          this.$toastr.e(this.$t('Message.246'), this.$t('Message.238'));
          this.isWithdraw = false;
        }
      },
      onKeyUpChangeBtnWithdraw() {
        if (
          this.formWithdraw.ecosystem === '' ||
          this.formWithdraw.address === '' ||
          this.formWithdraw.auth === '' ||
          this.formWithdraw.amount === ''
        ) {
          this.showBtnWithdraw = false;
          return;
        } else {
          this.showBtnWithdraw = true;
        }
      },
      // -----------------
      // Transfer
      async onTransfer(coinId) {
        this.formTransfer.coin = coinId;
        if (+this.formTransfer.amount < 10) {
          this.$toastr.e(this.$t('Message.247'), this.$t('Message.238'));
          this.formTransfer.amount = 0;
          this.formTransfer.auth = '';
          return;
        }
        this.onLoad();
        if (this.isTransfer) {
          this.$toastr.e(this.$t('Message.244'), this.$t('Message.238'));
          return;
        }
        this.formTransfer.token = await this.genarateCaptChaV3('transfer');
        this.$store.dispatch('wallet/req_transfer', this.formTransfer);
        await this.sleep(2000);
        this.isTransfer = false;
      },
      onKeyUpChangeBtnTransfer() {
        if (
          this.formTransfer.email === '' ||
          this.formTransfer.amount === '' ||
          this.formTransfer.auth === ''
        ) {
          this.showBtnTransfer = false;
          return;
        }
        this.showBtnTransfer = true;
      },
      // -----------------
      // Swap
      getCoinTo(coinTo) {
        if (coinTo === 3) {
          this.formSwap.coin_from = 8;
        } else {
          this.formSwap.coin_from = 3;
        }
      },
      async onSwap(coinId) {
        this.formSwap.coin_to = coinId;
        this.formSwap.amount = +this.formSwap.amount;
        this.onLoad();
        this.formSwap.token = await this.genarateCaptChaV3('transfer');
        this.$store.dispatch('wallet/req_swap', this.formSwap);
        this.outLoad();
      },
      keyupChangeAmount: function(coinKey, CoinRate) {
        if (this.formSwap.amount === '') {
          this.showBtnSwap = false;
          return;
        }
        this.showBtnSwap = true;
        if (coinKey == 'EBP') {
          this.amountToTransfer = this.formSwap.amount * CoinRate;
        } else {
          this.amountToTransfer = this.formSwap.amount / CoinRate;
        }
        this.amountFeeTransfer =
          this.amountToTransfer * this.listCoins[coinKey].FeeSwap;
        this.amountReceiveTransfer = this.amountToTransfer - this.amountFee;
      },
      // -----------------
      // Deposit Game
      async onDepositGame(nameGame) {
        if (nameGame === 'Casino SA') this.formDepositGame.typeDeposit = 1;
        if (nameGame === 'Slots Game') this.formDepositGame.typeDeposit = 4;
        if (nameGame === 'Fish Shooter') this.formDepositGame.typeDeposit = 4;
        if (nameGame === 'Sport Book') this.formDepositGame.typeDeposit = 2;

        this.formDepositGame.token = await this.genarateCaptChaV3(
          'depositGame'
        );
        if (this.formDepositGame.token) {
          if (this.Balance['EUSD'] >= this.formDepositGame.amount) {
            this.$store.dispatch(
              'wallet/req_depositGame',
              this.formDepositGame
            );
          } else {
            this.$toastr.e(this.$t('Message.248'), this.$t('Message.249'));
          }
        } else {
          this.$toastr.e(this.$t('Message.250'), this.$t('Message.249'));
        }
      },
      async onWithdrawGame(nameGame) {
        if (nameGame === 'Casino SA') this.formWithdrawGame.typeWithdraw = 1;
        if (nameGame === 'Slots Game') this.formWithdrawGame.typeWithdraw = 4;
        if (nameGame === 'Fish Shooter') this.formWithdrawGame.typeWithdraw = 4;
        if (nameGame === 'Sport Book') this.formWithdrawGame.typeWithdraw = 2;

        this.formWithdrawGame.token = await this.genarateCaptChaV3(
          'depositGame'
        );
        if (this.formWithdrawGame.token) {
          if (
            this.Balance[
              this.formWithdrawGame.typeWithdraw == 1
                ? 'Casino'
                : this.formWithdrawGame.typeWithdraw == 2
                ? 'SportBook'
                : this.formWithdrawGame.typeWithdraw == 3
                ? 'BinanryOption'
                : 'SkyGame'
            ] >= this.formWithdrawGame.amount
          ) {
            this.$store.dispatch(
              'wallet/req_withdrawGame',
              this.formWithdrawGame
            );
          } else {
            this.$toastr.e(this.$t('Message.248'), this.$t('Message.249'));
          }
        } else {
          this.$toastr.e(this.$t('Message.250'), this.$t('Message.249'));
        }
      },
      // -----------------
      // GameVM555
      async onRegisterVM555() {
        this.onLoad();
        this.formRegisterVM555.token = await this.genarateCaptChaV3(
          'registerVM555'
        );
        this.$store.dispatch(
          'wallet/req_registerVm555',
          this.formRegisterVM555
        );
      },
      async onDepositGameVM55() {
        this.onLoad();
        this.formDepositVM555.token = await this.genarateCaptChaV3(
          'depositVM555'
        );
        this.$store.dispatch('wallet/req_depositWM555', this.formDepositVM555);
      },
      async onWithdrawGameVM555() {
        this.onLoad();
        this.formWithdrawVM555.token = await this.genarateCaptChaV3(
          'withdrawVM555'
        );
        this.$store.dispatch(
          'wallet/req_withdrawWM555',
          this.formWithdrawVM555
        );
      },
      async onChangePasswordWM555() {
        this.formChangePassGameVM555.token = await this.genarateCaptChaV3(
          'changePassVM555'
        );
        this.$store.dispatch(
          'wallet/req_changePasssVm555',
          this.formChangePassGameVM555
        );
      },
      // Agin
      async onRegisterAgin() {
        this.onLoad();
        this.formRegisterAgin.token = await this.genarateCaptChaV3(
          'registerAgin'
        );
        this.$store.dispatch('wallet/req_registerAgin', this.formRegisterAgin);
      },
      async onDepositGameAgin() {
        this.onLoad();
        this.formDepositAgin.token = await this.genarateCaptChaV3(
          'depositAgin'
        );
        this.$store.dispatch('wallet/req_depositAgin', this.formDepositAgin);
      },
      async onWithdrawGameAgin() {
        this.onLoad();
        this.formWithdrawAgin.token = await this.genarateCaptChaV3(
          'withdrawAgin'
        );
        this.$store.dispatch('wallet/req_withdrawAgin', this.formWithdrawAgin);
      },
      async onChangePasswordAgin() {
        this.formChangePassGameAgin.token = await this.genarateCaptChaV3(
          'changePassAgin'
        );
        this.$store.dispatch(
          'wallet/req_changePasssAgin',
          this.formChangePassGameAgin
        );
      },
      async onLoginAgin(type) {
        this.onLoad();
        this.formLoginAgin.token = await this.genarateCaptChaV3('loginAgin');
        if (type === 'Agin Sport Book') {
          this.formLoginAgin.gameType = 'TASSPTA';
          this.$store.dispatch(
            'wallet/req_postLoginAginGame',
            this.formLoginAgin
          );
        }
        if (type === 'Agin Fish Shooter') {
          this.formLoginAgin.gameType = 'HMPL';
          this.$store.dispatch(
            'wallet/req_postLoginAginGame',
            this.formLoginAgin
          );
        }
        if (type === 'Agin Slot') {
          this.formLoginAgin.gameType = '8';
          this.$store.dispatch(
            'wallet/req_postLoginAginGame',
            this.formLoginAgin
          );
        }
      },
      // Evolution
      async onRegisterEvolution() {
        this.onLoad();
        this.formRegisterEvolution.token = await this.genarateCaptChaV3(
          'registerEvolution'
        );
        if (
          this.formRegisterEvolution.password !==
          this.formRegisterEvolution.password_confirm
        ) {
          this.outLoad();
          this.$toastr.e(
            'Confirm password must be the same as the old password',
            'Failed'
          );
          return;
        }
        this.$store.dispatch('wallet/req_registerEvolution', {
          password: this.formRegisterEvolution.password,
        });
      },
      async onDepositGameEvolution() {
        this.onLoad();
        this.formDepositEvolution.token = await this.genarateCaptChaV3(
          'depositEvolution'
        );
        this.$store.dispatch(
          'wallet/req_depositEvolution',
          this.formDepositEvolution
        );
      },
      async onWithdrawGameEvolution() {
        this.onLoad();
        this.formWithdrawEvolution.token = await this.genarateCaptChaV3(
          'withdrawEvolution'
        );
        this.$store.dispatch(
          'wallet/req_withdrawEvolution',
          this.formWithdrawEvolution
        );
      },
      async onChangePasswordEvolution() {
        this.formChangePassGameEvolution.token = await this.genarateCaptChaV3(
          'changePassEvolution'
        );
        this.$store.dispatch(
          'wallet/req_changePasssEvolution',
          this.formChangePassGameEvolution
        );
      },
      async onLoginEvolution(type) {
        console.log(type);
        this.onLoad();
        this.formLoginEvolution.token = await this.genarateCaptChaV3(
          'loginEvolution'
        );
        this.$store.dispatch(
          'wallet/req_loginEvolution',
          this.formLoginEvolution
        );
      },
      // --------------------
      onCopy(e) {
        this.content = e.text;
        this.$bvModal.show(`modal-copy-transaction-${this.type}`);
        this.$bvModal.hide(`modal-transaction-${this.type}`);
      },
      onError() {
        alert('Failed to copy texts');
      },
      // -----------------
      // Bonus
      handleWithdrawBonus: async function() {
        const token = await this.genarateCaptChaV3('deposit');
        // const amount = this.Agency.Static.maxWithdtawBonus
        //   .replace('EUSD', '')
        //   .replace(',', '');
        const amount = this.amountWithdraw;
        await this.$store.dispatch('wallet/req_postWithdrawBonus', {
          token: token,
          amount: parseFloat(amount),
          password: this.passwordWithdraw.toString(),
        });
        this.passwordWithdraw = '';
        this.onKeyUpChangeBtnWithdrawBonus();
      },
      onKeyUpChangeBtnWithdrawBonus() {
        if (this.passwordWithdraw !== '' && +this.amountWithdraw >= 50) {
          this.showBtnWithdrawBonus = true;
          return;
        } else {
          this.showBtnWithdrawBonus = false;
        }
      },
      handleCancelModal() {
        this.$bvModal.hide('withdrawBonus-wallet');
      },
      modalWithdrawBonus() {
        this.$bvModal.show('withdrawBonus-wallet');
      },
      // -----------------
      changeAction(action) {
        this.$emit('changeAction', action);
      },
    },
    watch: {
      Info: {
        handler(newVal) {
          if (newVal.connectAddressMetamask) {
            if (this.isLogin) {
              this.formWithdraw.address = this.Info.walletAddress;
            }
          }
        },
      },
    },
    created() {
      if (this.isLogin) {
        // this.$store.dispatch('wallet/req_getCoin');
        // this.$store.dispatch('wallet/req_getListEcosystem');
      }
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        // eslint-disable-next-line default-case
        switch (mutation.type) {
          case 'core/ERROR_MESSANGE':
            this.formWithdraw.auth = '';
            // this.showBtnWithdraw = false;
            this.formTransfer.auth = '';
            break;
          case 'core/SUCCESS_MESSANGE':
            this.formWithdraw.auth = '';
            this.formWithdraw.amount = 0;
            this.amountFee = 0;
            // this.showBtnWithdraw = false;
            this.formTransfer.auth = '';
            this.formTransfer.email = '';
            this.formTransfer.amount = 0;
            break;
          // case 'wallet/WITHDRAW_BONUS_SUCCESS':
          //   this.$store.dispatch('dashboard/req_getAgency');
          //   break;
          case 'wallet/REGISTER_AGIN_SUCCESS':
            this.showRegisterAign = 0;
            break;
          case 'wallet/DEPOSIT_AGIN_SUCCESS':
            this.showDepositGame = 0;
            this.formDepositAgin = {
              amount: '',
              password: '',
              token: '',
            };
            break;
          case 'wallet/CHANGEPASSWORD_AGIN_SUCCESS':
            this.showChangePassAgin = 0;
            this.formChangePassGameAgin = {
              password: '',
              new_password: '',
              confirm_password: '',
              token: '',
            };
            break;
          case 'wallet/WITHDRAW_AGIN_SUCCESS':
            this.showWithdrawGame = 0;
            this.formWithdrawAgin = {
              amount: '',
              password: '',
              token: '',
            };
          case 'wallet/LOGIN_AIGN_SUCCESS':
            this.formLoginAgin = {
              password: '',
              token: '',
            };
            this.showNoticePlayAgin = 0;
            this.showBtnPlayGameAgin = 1;
            this.outLoad();
            break;
          // Evolution --------------------------
          case 'wallet/REGISTER_EVOLUTION_SUCCESS':
            this.showRegisterEvolution = 0;
            break;
          case 'wallet/DEPOSIT_EVOLUTION_SUCCESS':
            this.showDepositGame = 0;
            this.formDepositEvolution = {
              amount: '',
              password: '',
              token: '',
            };
            break;
          case 'wallet/CHANGEPASSWORD_EVOLUTION_SUCCESS':
            this.showChangePassEvolution = 0;
            this.formChangePassGameEvolution = {
              password: '',
              new_password: '',
              confirm_password: '',
              token: '',
            };
            break;
          case 'wallet/WITHDRAW_EVOLUTION_SUCCESS':
            console.log('ádasds');
            this.showWithdrawGame = 0;
            this.formWithdrawEvolution = {
              amount: '',
              password: '',
              token: '',
            };
          case 'wallet/LOGIN_EVOLUTION_SUCCESS':
            this.formLoginEvolution = {
              password: '',
              token: '',
            };
            this.showNoticePlayEvolution = 0;
            this.showBtnPlayGameEvoluiton = 1;
            this.outLoad();
            break;
        }
      });
    },
    beforeDestroy() {
      this.unsubscribe();
    },
  };
</script>

<style lang="scss">
  // .modal.show .modal-dialog {
  //   width: 100%;
  //   height: 100%;
  // }
  #withdrawBonus-wallet {
    &.modal {
      .modal-dialog {
        height: auto;
        border-radius: 1.25rem;
        @media (max-width: 767px) {
          margin: 0px;
          margin-top: 50px;
        }
        .modal-content {
          background-color: #17181b;
          border-radius: 1.25rem;
          .modal-body {
            border: 1px solid #fff;
            border-radius: 1.25rem;
            .title {
              color: rgba(153, 164, 176, 0.8);
            }
            @media (max-width: 991px) {
              height: 100vh;
              overflow-y: auto;
            }
            &::-webkit-scrollbar {
              width: 5px;
            }
          }
        }
      }
    }
    p {
      color: rgba(153, 164, 176, 0.8);
      margin-bottom: 5px;
      margin-top: 20px;
    }
    .form-control {
      border: 1px solid rgb(45, 48, 53);
      background-color: rgba(84, 86, 88, 0.452);
      border-left: none;
      border-radius: 15px;
      color: rgb(212, 250, 77);
      &:focus {
        background: none;
        border: 1px solid rgb(123, 197, 20);
        outline: none;
        box-shadow: none;
        color: rgb(212, 250, 77);
      }
    }
    .custom-btn {
      button {
        &:nth-child(1) {
          height: 100%;
          width: 7.25rem;
          color: #f5f6f7;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #5da000;
          background-image: conic-gradient(from 1turn, #458802, #7bc514);
          border-radius: 6.25rem;
          font-weight: bold;
          border: none;
        }
        &:nth-child(2) {
          height: 100%;
          width: 7.25rem;
          color: #f5f6f7;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #a00000;
          background-image: conic-gradient(from 1turn, #880202, #c51414);
          border-radius: 6.25rem;
          font-weight: bold;
          border: none;
        }
      }
    }
  }
  .modal-transaction {
    &.modal {
      display: flex !important;
      justify-content: center;
      align-items: flex-start;
      padding-left: 0px !important;
      .modal-dialog {
        width: 100%;
        max-width: 600px;
        .modal-content {
          height: auto;
          border-radius: 1.25rem;
          position: relative;
          background-color: rgb(23, 24, 27);
          @media (max-width: 480px) {
            font-size: 14px;
            padding: 0rem;
          }
        }
      }
    }
  }
  .modal-body {
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
    margin-top: 20px;
    overflow-x: hidden;
    background-color: none;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
</style>
<style lang="scss" scoped>
  .widget-content-light {
    color: rgba(153, 164, 176, 0.6);
  }
  .bonus-content {
    .game {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      justify-self: center;
      padding-bottom: 5px;
      width: 100%;
      color: rgba(153, 164, 176, 0.6);
      button {
        border: none;
        width: 8rem;
        height: 30px;
        margin: 0px 5px;
        color: #f5f6f7;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-image: conic-gradient(from 1turn, #581ac4, #773cfd);
        border-radius: 6.25rem;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .modal-transaction {
    .has-close {
      position: relative;
      z-index: 10;
      flex: 0 0 auto;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 3.75rem;
      margin-left: 1.125rem;
      margin-right: 3.75rem;
      transition: all 0.5s ease 0s;
      .title {
        font-size: 1rem;
        margin: 0px;
        font-weight: bold;
        flex: 1 1 0%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(245, 246, 247);
      }
      .content {
        display: flex;
        button {
          color: rgba(153, 164, 176, 0.8);
          border: none;
          background: none;
          svg {
            display: inline-block;
            vertical-align: top;
            width: 1.125rem;
            height: 1.125rem;
            margin: 0px 0.5rem 0px 0px;
            color: rgba(153, 164, 176, 0.6);
          }
        }
      }
    }
    .close-icon {
      position: absolute;
      right: -10px;
      top: -15px;
      z-index: 11;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 3.75rem;
      height: 3.75rem;
      border: none;
      background: none;
      color: rgba(153, 164, 176, 0.8);
    }
    .transaction-content {
      .action-withdraw-header {
        margin-top: 1rem;
        width: 100%;
        font-size: 0.8125rem;
        .label {
          margin: 0px 0.75rem 0.375rem;
          color: rgba(153, 164, 176, 0.6);
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
        }
        .box {
          background-color: rgba(45, 48, 53, 0.5);
          border: 1px solid rgb(45, 48, 53);
          padding: 0.3125rem 1.25rem;
          width: 100%;
          border-radius: 1.25rem;
          min-height: 3.5rem;
          display: flex;
          flex-direction: column;
          .type {
            display: flex;
            margin-top: 0.3125rem;
            flex-wrap: wrap;
            justify-content: center;
            button {
              color: rgba(153, 164, 176, 0.6);
              background-color: rgb(45, 48, 53);
              cursor: pointer;
              border: 1px solid rgb(45, 48, 53);
              min-width: 4.6875rem;
              padding: 0px 0.625rem;
              height: 1.75rem;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              border-radius: 0.875rem;
              margin-right: 0.1875rem;
              margin-bottom: 10px;
              font-size: 12px;
              &.active {
                color: rgb(255, 255, 255);
                border: 1px solid rgb(93, 160, 0);
                font-weight: 800;
                background-color: rgba(93, 160, 0, 0.15);
              }
            }
          }
          .cont {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            flex: 1 1 auto;
            .form-control {
              margin-top: 20px;
              border: 1px solid rgb(45, 48, 53);
              background-color: rgba(45, 48, 53, 0.5);
              border-left: none;
              border-radius: 15px;
              &:focus {
                background: none;
                border: 1px solid rgb(123, 197, 20);
                outline: none;
                box-shadow: none;
              }
            }
          }
        }
      }
      .transaction-content-action {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        background-color: rgb(30, 32, 36);
        border-radius: 1.25rem;
        margin: 0.25rem 0.625rem 0.625rem;
        order: 0;
        flex: 0 0 auto;
        font-size: 12px;
        .action-item {
          position: relative;
          z-index: 2;
          height: 5rem;
          width: 5rem;
          padding: 0.625rem 0px;
          text-align: center;
          cursor: pointer;
          line-height: 1;
          border-radius: 1.25rem;
          &:hover {
            background-color: rgba(106, 108, 109, 0.39);
          }
          &.action-active {
            background-color: rgb(93, 160, 0);
            color: rgb(255, 255, 255);
          }
          img {
            width: 1.375rem;
            height: 1.375rem;
            color: rgb(255, 255, 255);
            margin-top: 0.625rem;
          }
          .action-title {
            margin-top: 0.3125rem;
            color: rgb(255, 255, 255);
          }
        }
      }
      .action-deposit {
        color: rgba(153, 164, 176, 0.6);
        height: 100%;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          background-color: transparent;
          border-radius: 5px;
        }
        .action-deposit-header {
          margin-top: 1rem;
          width: 100%;
          font-size: 0.8125rem;
          .label {
            margin: 0px 0.75rem 0.375rem;
            color: rgba(153, 164, 176, 0.6);
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
          }
          .box {
            background-color: rgba(45, 48, 53, 0.5);
            border: 1px solid rgb(45, 48, 53);
            padding: 0.3125rem 1.25rem;
            width: 100%;
            border-radius: 1.25rem;
            min-height: 3.5rem;
            display: flex;
            flex-direction: column;
            .cont {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              flex: 1 1 auto;
              .address {
                flex: 1 1 auto;
                word-break: break-all;
                color: rgb(245, 246, 247);
                input {
                  border: none;
                  background: none;
                  color: rgb(245, 246, 247);
                  width: 100%;
                }
              }
              .copy-button {
                font-weight: bold;
                cursor: pointer;
                transition: transform 0.2s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
                border-radius: 6.25rem;
                margin-right: -1.25rem;
                width: 2.5rem;
                height: 1.875rem;
                flex: 0 0 auto;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                border: none;
                background: none;
                svg {
                  color: rgba(153, 164, 176, 0.6);
                }
              }
            }
          }
        }
        .action-deposit-qrcode {
          width: 11.125rem;
          height: 11.125rem;
          margin: 1rem auto;
          padding: 0.75rem;
          border-radius: 1.25rem;
          overflow: hidden;
          background-color: rgb(45, 48, 53);
          img {
            width: 100%;
            border-radius: 0.625rem;
            image-rendering: pixelated;
          }
        }
        .action-notification {
          padding: 0.625rem 1rem;
          border-radius: 1.25rem;
          font-size: 1rem;
          border: 1px solid #821f1f;
          color: rgb(255, 255, 255);
          margin-top: 10px;
          p {
            margin: 0.8em 0px;
            color: rgb(255, 255, 255);
          }
        }
      }
      .action-withdraw {
        color: rgba(153, 164, 176, 0.6);
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          background-color: transparent;
          border-radius: 5px;
        }
        .action-withdraw-header {
          margin-top: 1rem;
          width: 100%;
          font-size: 0.8125rem;
          .label {
            margin: 0px 0.75rem 0.375rem;
            color: rgba(153, 164, 176, 0.6);
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
          }
          .box {
            background-color: rgba(45, 48, 53, 0.5);
            border: 1px solid rgb(45, 48, 53);
            padding: 0.3125rem 1.25rem;
            width: 100%;
            border-radius: 1.25rem;
            min-height: 3.5rem;
            display: flex;
            flex-direction: column;
            .type {
              display: flex;
              margin-top: 0.3125rem;
              flex-wrap: wrap;
              justify-content: center;
              button {
                color: rgba(153, 164, 176, 0.6);
                background-color: rgb(45, 48, 53);
                cursor: pointer;
                border: 1px solid rgb(45, 48, 53);
                min-width: 4.6875rem;
                padding: 0px 0.625rem;
                height: 1.75rem;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                border-radius: 0.875rem;
                margin-right: 0.1875rem;
                margin-bottom: 10px;
                font-size: 12px;
                &.active {
                  color: rgb(255, 255, 255);
                  border: 1px solid rgb(93, 160, 0);
                  font-weight: 800;
                  background-color: rgba(93, 160, 0, 0.15);
                }
              }
            }
            .cont {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              flex: 1 1 auto;
              .form-control {
                margin-top: 20px;
                border: 1px solid rgb(45, 48, 53);
                background-color: rgba(45, 48, 53, 0.5);
                border-left: none;
                border-radius: 15px;
                &:focus {
                  background: none;
                  border: 1px solid rgb(123, 197, 20);
                  outline: none;
                  box-shadow: none;
                }
              }
            }
          }
        }
        .action-withdraw-qrcode {
          width: 100%;
          height: auto;
          margin: 1rem auto;
          padding: 0.75rem;
          border-radius: 1.25rem;
          overflow: hidden;
          background-color: rgb(45, 48, 53);
          text-align: left;
          p {
            color: rgba(153, 164, 176, 0.8);
            margin-bottom: 5px;
            margin-top: 20px;
          }
          .form-control {
            border: 1px solid rgb(45, 48, 53);
            background-color: rgba(84, 86, 88, 0.452);
            border-left: none;
            border-radius: 15px;
            color: rgb(212, 250, 77);
            &:focus {
              background: none;
              border: 1px solid rgb(123, 197, 20);
              outline: none;
              box-shadow: none;
              color: rgb(212, 250, 77);
            }
          }
          .custom-select {
            border: 1px solid rgb(45, 48, 53);
            background: rgba(84, 86, 88, 0.452)
              url('~@/assets/images/home/arrow.png') right 0.75rem center/8px
              10px no-repeat;
            border-left: none;
            border-radius: 15px;
            color: rgb(212, 250, 77);
            &:focus {
              background: rgba(84, 86, 88, 0.452)
                url('~@/assets/images/home/arrow.png') right 0.75rem center/8px
                10px no-repeat;
              border: 1px solid rgb(123, 197, 20);
              outline: none;
              box-shadow: none;
            }
            option {
              background-color: #292828;
            }
          }
          .custom-btn {
            width: 100%;
            display: flex;
            justify-content: center;
            .button {
              border: none;
              width: 12.5rem;
              height: 50px;
              margin: 0px;
              color: rgb(245, 246, 247);
              box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
              background-color: rgb(88, 26, 196);
              background-image: conic-gradient(
                from 1turn,
                rgb(88, 26, 196),
                rgb(119, 60, 253)
              );
              border-radius: 6.25rem;
              font-weight: bold;
              cursor: pointer;
              @media (max-width: 480px) {
                width: 10rem;
              }
            }
            .btn-disable {
              cursor: not-allowed !important;
              background-image: conic-gradient(
                from 1turn,
                rgb(124, 92, 179),
                rgb(167, 129, 255)
              );
            }
          }
        }
        .action-notification {
          padding: 0.625rem 1rem;
          border-radius: 1.25rem;
          font-size: 1rem;
          border: 1px solid #821f1f;
          color: rgb(255, 255, 255);
          margin-top: 10px;
          p {
            margin: 0.8em 0px;
            color: rgb(255, 255, 255);
          }
        }
      }
      .listGame {
        color: rgba(153, 164, 176, 0.6);
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          background-color: transparent;
          border-radius: 5px;
        }
        .action-game-header {
          margin-top: 1rem;
          width: 100%;
          font-size: 0.8125rem;
          .label {
            margin: 0px 0.75rem 0.375rem;
            color: rgba(153, 164, 176, 0.6);
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
          }
          .box {
            background-color: rgba(45, 48, 53, 0.5);
            border: 1px solid rgb(45, 48, 53);
            padding: 0.3125rem 1.25rem;
            width: 100%;
            border-radius: 1.25rem;
            min-height: 3.5rem;
            display: flex;
            flex-direction: column;
            .type {
              display: flex;
              flex-wrap: wrap;
              margin-top: 0.3125rem;
              justify-content: center;
              button {
                color: rgba(153, 164, 176, 0.6);
                background-color: rgb(45, 48, 53);
                cursor: pointer;
                border: 1px solid rgb(45, 48, 53);
                min-width: 4.6875rem;
                padding: 0px 0.625rem;
                height: 1.75rem;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                border-radius: 0.875rem;
                margin-right: 0.5rem;
                margin-bottom: 10px;
                font-size: 12px;
                &.active {
                  color: rgb(255, 255, 255);
                  border: 1px solid rgb(93, 160, 0);
                  font-weight: 800;
                  background-color: rgba(93, 160, 0, 0.15);
                }
              }
            }
            .cont {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              flex: 1 1 auto;
              .form-control {
                margin-top: 20px;
                border: 1px solid rgb(45, 48, 53);
                background-color: rgba(45, 48, 53, 0.5);
                border-left: none;
                border-radius: 15px;
                &:focus {
                  background: none;
                  border: 1px solid rgb(123, 197, 20);
                  outline: none;
                  box-shadow: none;
                }
              }
            }
          }
        }
        .action-game-button {
          width: 100%;
          height: auto;
          margin: 1rem auto;
          padding: 0.75rem;
          border-radius: 1.25rem;
          overflow: hidden;
          background-color: rgb(45, 48, 53);
          text-align: center;
          .button {
            border: none;
            width: 8rem;
            height: 50px;
            margin: 0px 5px;
            color: rgb(245, 246, 247);
            box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
            background-image: conic-gradient(
              from 1turn,
              rgb(88, 26, 196),
              rgb(119, 60, 253)
            );
            border-radius: 6.25rem;
            font-weight: bold;
            cursor: pointer;
            @media (max-width: 500px) {
              margin-bottom: 10px;
            }
            @media (max-width: 480px) {
              width: 10rem;
            }
            &.bg-withdraw {
              background-image: conic-gradient(from 1turn, #458802, #7bc514);
            }
            &.bg-deposit {
              background-image: conic-gradient(from 1turn, #c48e1a, #fda33c);
            }
            &.bg-play {
              background-image: conic-gradient(from 1turn, #c41a1a, #fd3c3c);
              a {
                color: white;
              }
            }
          }
          .form-deposit-game {
            h5 {
              margin-top: 20px;
              margin-bottom: 0px;
            }
            p {
              color: rgba(153, 164, 176, 0.8);
              margin-bottom: 5px;
              margin-top: 20px;
              text-align: left;
            }
            .form-control {
              border: 1px solid rgb(45, 48, 53);
              background-color: rgba(84, 86, 88, 0.452);
              border-left: none;
              border-radius: 15px;
              color: rgb(212, 250, 77);
              &:focus {
                background: none;
                border: 1px solid rgb(123, 197, 20);
                outline: none;
                box-shadow: none;
                color: rgb(212, 250, 77);
              }
            }
            .button {
              margin-top: 20px;
              background-image: conic-gradient(from 1turn, #458802, #7bc514);
              &.bg-cancel {
                background-image: conic-gradient(from 1turn, #c41a1a, #fd3c3c);
              }
            }
          }
          .form-withdraw-game {
            h5 {
              margin-top: 20px;
              margin-bottom: 0px;
            }
            p {
              color: rgba(153, 164, 176, 0.8);
              margin-bottom: 5px;
              margin-top: 20px;
              text-align: left;
            }
            .form-control {
              border: 1px solid rgb(45, 48, 53);
              background-color: rgba(84, 86, 88, 0.452);
              border-left: none;
              border-radius: 15px;
              color: rgb(212, 250, 77);
              &:focus {
                background: none;
                border: 1px solid rgb(123, 197, 20);
                outline: none;
                box-shadow: none;
                color: rgb(212, 250, 77);
              }
            }
            .button {
              margin-top: 20px;
              background-image: conic-gradient(from 1turn, #c48e1a, #fda33c);
            }
          }
          .form-resgiter-vm555 {
            h5 {
              margin-top: 20px;
              margin-bottom: 0px;
            }
            p {
              color: rgba(153, 164, 176, 0.8);
              margin-bottom: 5px;
              margin-top: 20px;
              text-align: left;
            }
            .form-control {
              border: 1px solid rgb(45, 48, 53);
              background-color: rgba(84, 86, 88, 0.452);
              border-left: none;
              border-radius: 15px;
              color: rgb(212, 250, 77);
              &:focus {
                background: none;
                border: 1px solid rgb(123, 197, 20);
                outline: none;
                box-shadow: none;
                color: rgb(212, 250, 77);
              }
            }
            .button {
              margin-top: 20px;
              background-image: conic-gradient(from 1turn, #c48e1a, #fda33c);
            }
          }
          .notificationPlay {
            h3 {
              color: #c41a1a;
              font-weight: bold;
            }
            p {
              color: #82ff0e;
            }
          }
          span {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            margin-bottom: 20px;
          }
        }
        .action-notification {
          padding: 0.625rem 1rem;
          border-radius: 1.25rem;
          font-size: 1rem;
          border: 1px solid #821f1f;
          color: rgb(255, 255, 255);
          margin-top: 10px;
          p {
            margin: 0.8em 0px;
            color: rgb(255, 255, 255);
          }
        }
      }
    }
    .transaction-bottom {
      position: relative;
      padding: 36px 0px;
      border-top: 1px solid rgba(128, 141, 152, 0.1);
      .transaction-bottom-flex {
        margin: 0px auto;
        display: flex;
        flex: 0 0 auto;
        -webkit-box-pack: justify;
        justify-content: space-between;
        background-color: rgb(30, 32, 36);
        width: 25rem;
        border-radius: 2.4375rem;
        height: 3.5rem;
        @media (max-width: 480px) {
          width: 100%;
        }
        .cont {
          display: flex;
          width: 11.5rem;
          padding: 0.625rem 0.625rem 0.625rem 0.625rem;
          line-height: 1.125rem;
          opacity: 0.8;
          color: rgba(153, 164, 176, 0.6);
          font-size: 14px;
          @media (max-width: 480px) {
            font-size: 12px;
          }
        }
        .button {
          border: none;
          width: 12.5rem;
          height: 100%;
          margin: 0px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(88, 26, 196);
          background-image: conic-gradient(
            from 1turn,
            rgb(88, 26, 196),
            rgb(119, 60, 253)
          );
          border-radius: 6.25rem;
          font-weight: bold;
          cursor: pointer;
          @media (max-width: 480px) {
            width: 10rem;
          }
        }
      }
      .title {
        margin-bottom: 0.5rem;
        text-align: center;
        color: rgba(153, 164, 176, 0.6);
      }
      .support-wrap {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        line-height: 1.75rem;
        .support-item {
          width: 48%;
          text-align: center;
          position: relative;
          cursor: pointer;
          color: rgb(255, 255, 255);
          img {
            height: 1.875rem;
            width: auto;
            display: inline-block;
            vertical-align: top;
            margin-right: 0.75rem;
          }
        }
      }
    }
  }
  .Casino-WM555-btnGroup {
    button {
      @media (max-width: 576px) {
        margin-bottom: 10px;
      }
    }
  }
</style>
