import Vue from 'vue';
import VueCookies from 'vue-cookies';
import store from '../../store';

Vue.use(VueCookies);
const token = window.$cookies.get('isSession');
export default function log({ next, to, router }) {
	if (token) {
		store.commit('login');
		if (to.name === 'login') {
			return router.push({ name: 'QuanLyTaiKhoan' });
		}
	}
	return next();
}
