import Vue from 'vue';
import Vuex from 'vuex';
import auth from './Auth/index';
import core from './config/core';
import dashboard from './Dashboard/index';
import wallet from './Wallet/index';
import member from './Member/index';
import ticket from './Ticket/index';
import spin from './Spin/index';
import promotion from './Promotion/Index';
import tournament from './Tournament/index';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loadCount: 0,
    isLoad: false,
    isLogin: sessionStorage.getItem('isLogin') || false,
    active1: '',
    game: {
      url: '',
      show: false,
    },
  },
  mutations: {
    onLoad(state) {
      state.loadCount += 1;
      state.isLoad = true;
    },
    outLoad(state) {
      state.loadCount -= 1;
      if (state.loadCount <= 0) {
        state.isLoad = false;
        state.loadCount = 0;
      }
    },
    login(state) {
      state.isLogin = true;
    },
    logout(state) {
      state.isLogin = false;
    },
    SET_ACTIVE(state, data) {
      state.active1 = data;
    },
    HiddenChat() {},
    getGame(state, payload) {
      state.game.url = payload.src;
      state.game.show = payload.status;
    },
  },
  getters: {
    Active1: (state) => state.active1,
    Game: (state) => state.game,
  },
  actions: {},
  modules: {
    auth,
    core,
    dashboard,
    wallet,
    member,
    ticket,
    spin,
    promotion,
    tournament,
  },
});
